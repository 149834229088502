const CameraIconV2 = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.555 7.195C22.105 6.745 21.51 6.5 20.875 6.5H18.11C18.005 6.5 17.865 6.425 17.755 6.315L16.545 4.405C16.525 4.37 16.495 4.335 16.47 4.305C16.025 3.785 15.425 3.5 14.78 3.5H10.47C9.825 3.5 9.22 3.785 8.78 4.305C8.755 4.335 8.73 4.37 8.705 4.405L7.495 6.315C7.42 6.395 7.3 6.5 7.19 6.5H4.375C3.74 6.5 3.145 6.745 2.695 7.195C2.245 7.645 2 8.24 2 8.875V17.875C2 18.51 2.245 19.105 2.695 19.555C3.145 20.005 3.74 20.25 4.375 20.25H20.875C21.51 20.25 22.105 20.005 22.555 19.555C23.005 19.105 23.25 18.51 23.25 17.875V8.875C23.25 8.24 23.005 7.645 22.555 7.195ZM12.625 16.965C10.14 16.965 8.125 15.01 8.125 12.6C8.125 10.19 10.14 8.235 12.625 8.235C15.11 8.235 17.125 10.19 17.125 12.6C17.125 15.01 15.11 16.965 12.625 16.965Z"
        fill="white"
      />
      <path
        d="M12.625 15.625C14.2819 15.625 15.625 14.2819 15.625 12.625C15.625 10.9681 14.2819 9.625 12.625 9.625C10.9681 9.625 9.625 10.9681 9.625 12.625C9.625 14.2819 10.9681 15.625 12.625 15.625Z"
        fill="white"
      />
    </svg>
  );
};

export default CameraIconV2;
