const TouchIcon = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0725 21.2625C6.8925 21.2625 5.4025 19.8525 3.6375 17.8575C3.1775 17.3375 1.7625 15.8075 1.7625 15.8075L1.4775 15.4875C0.712498 14.6475 0.247498 14.1375 0.247498 13.4975C0.247498 12.8575 0.652498 12.2425 1.3575 11.8975C2.2825 11.4375 3.7375 11.3775 4.7375 12.1475C4.8625 12.2425 5.0175 12.3775 5.1975 12.5475V6.90751C5.1975 5.59751 6.2275 4.52251 7.4925 4.51251C8.7875 4.51251 9.8425 5.58751 9.8425 6.90751V10.1475H12.3525C14.2925 10.1475 15.7525 11.5375 15.7525 13.3775V15.5825C15.7525 17.8925 15.2225 20.9225 10.8225 21.2275V21.2525H10.0725V21.2625ZM9.7625 19.7625H10.0725C13.5525 19.7625 14.2525 18.1675 14.2525 15.5925V13.3875C14.2525 12.3825 13.4525 11.6575 12.3525 11.6575H9.5025C8.8625 11.6575 8.3475 11.1375 8.3475 10.5025V6.91751C8.3475 6.43251 7.9625 6.02251 7.5025 6.02251C7.0575 6.02251 6.7025 6.41751 6.7025 6.91751V13.8875C6.7025 14.2775 6.4675 14.6225 6.1075 14.7675C5.7425 14.9125 5.3325 14.8275 5.0625 14.5425C4.3425 13.7825 3.9975 13.4725 3.8325 13.3475C3.3475 12.9775 2.5225 13.0075 2.0325 13.2525C1.8825 13.3275 1.7825 13.4125 1.7625 13.4825C1.8525 13.6725 2.3175 14.1825 2.6025 14.4925L2.8825 14.8075C2.8825 14.8075 4.3075 16.3475 4.7725 16.8725C6.3025 18.6025 7.4225 19.6775 9.7725 19.7625H9.7625Z"
        fill="#1F2024"
      />
      <path
        d="M12.4075 7.28749C11.9775 7.28749 11.6325 6.94249 11.6325 6.51249C11.6325 4.18249 9.73751 2.28749 7.40751 2.28749C5.07751 2.28749 3.18751 4.17749 3.18751 6.50749C3.18751 6.93749 2.84251 7.28249 2.41251 7.28249C1.98251 7.28249 1.63751 6.93749 1.63751 6.50749C1.63751 3.32749 4.22751 0.737488 7.40751 0.737488C10.5875 0.737488 13.1825 3.32749 13.1825 6.51249C13.1825 6.94249 12.8375 7.28749 12.4075 7.28749Z"
        fill="#1F2024"
      />
    </svg>
  );
};

export default TouchIcon;
