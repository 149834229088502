export const CROP_IMAGES_DEFAULT = "https://static.farmmorning.com/glCrop/common_ic_default.png";

// Plantix 작물에서 팜모닝에 있는 이미지 매핑
export const CROP_IMAGES_PLANTIX: { [key: string]: string } = {
  아몬드: "",
  사과: "https://static.farmmorning.com/glCrop/common_ic_apple.png",
  살구: "",
  바나나: "",
  보리: "https://static.farmmorning.com/glCrop/common_ic_grain.png",
  콩: "https://static.farmmorning.com/glCrop/common_ic_bean.png",
  여주: "",
  양배추: "https://static.farmmorning.com/glCrop/common_ic_yangbaechu.png",
  유채: "",
  당근: "",
  "브로콜리(녹색꽃양배추)": "",
  체리: "",
  병아리콩: "https://static.farmmorning.com/glCrop/common_ic_bean.png",
  감귤: "https://static.farmmorning.com/glCrop/common_ic_mandarin.png",
  커피: "",
  "목화, 면화": "",
  오이: "https://static.farmmorning.com/glCrop/common_ic_cucumber.png",
  코린트: "",
  가지: "https://static.farmmorning.com/glCrop/common_ic_eggplant.png",
  생강: "https://static.farmmorning.com/glCrop/common_ic_ginger.png",
  녹두: "https://static.farmmorning.com/glCrop/common_ic_bean.png",
  포도: "https://static.farmmorning.com/glCrop/common_ic_grape.png",
  구아바: "",
  옥수수: "https://static.farmmorning.com/glCrop/common_ic_corn.png",
  망고: "",
  카사바: "",
  멜론: "",
  기장: "https://static.farmmorning.com/glCrop/common_ic_grain.png",
  오크라: "",
  올리브: "",
  양파: "https://static.farmmorning.com/glCrop/common_ic_onion.png",
  파파야: "",
  복숭아: "https://static.farmmorning.com/glCrop/common_ic_peach.png",
  땅콩: "",
  완두: "https://static.farmmorning.com/glCrop/common_ic_bean.png",
  고추: "https://static.farmmorning.com/glCrop/common_ic_pepper.png",
  비둘기콩: "https://static.farmmorning.com/glCrop/common_ic_bean.png",
  피스타치오: "",
  석류나무: "",
  감자: "https://static.farmmorning.com/glCrop/common_ic_potato.png",
  호박: "",
  논벼: "https://static.farmmorning.com/glCrop/common_ic_grain.png",
  장미: "",
  수수: "https://static.farmmorning.com/glCrop/common_ic_grain.png",
  딸기: "https://static.farmmorning.com/glCrop/common_ic_strawberry.png",
  사탕무: "",
  사탕수수: "",
  담배: "",
  토마토: "https://static.farmmorning.com/glCrop/common_ic_tomato.png",
  강황: "",
  밀: "https://static.farmmorning.com/glCrop/common_ic_grain.png",
  쥬키니호박: "",
};

// 팜모닝 TOP30이미지
const CROP_IMAGES_FARMMORNING = {
  고추: "https://static.farmmorning.com/glCrop/common_ic_pepper.png",
  벼: "https://static.farmmorning.com/glCrop/common_ic_grain.png",
  감자: "https://static.farmmorning.com/glCrop/common_ic_potato.png",
  고구마: "https://static.farmmorning.com/glCrop/common_ic_sweet-potato.png",
  사과: "https://static.farmmorning.com/glCrop/common_ic_apple.png",
  딸기: "https://static.farmmorning.com/glCrop/common_ic_strawberry.png",
  마늘: "https://static.farmmorning.com/glCrop/common_ic_garlic.png",
  상추: "https://static.farmmorning.com/glCrop/common_ic_lettuce.png",
  배추: "https://static.farmmorning.com/glCrop/common_ic_cabbage.png",
  토마토: "https://static.farmmorning.com/glCrop/common_ic_tomato.png",
  포도: "https://static.farmmorning.com/glCrop/common_ic_grape.png",
  콩: "https://static.farmmorning.com/glCrop/common_ic_bean.png",
  귤: "https://static.farmmorning.com/glCrop/common_ic_mandarin.png",
  복숭아: "https://static.farmmorning.com/glCrop/common_ic_peach.png",
  양파: "https://static.farmmorning.com/glCrop/common_ic_onion.png",
  감: "https://static.farmmorning.com/glCrop/common_ic_persimmon.png",
  대파: "https://static.farmmorning.com/glCrop/common_ic_leek.png",
  들깨: "https://static.farmmorning.com/glCrop/common_ic_perilla.png",
  오이: "https://static.farmmorning.com/glCrop/common_ic_cucumber.png",
  옥수수: "https://static.farmmorning.com/glCrop/common_ic_corn.png",
  표고버섯: "https://static.farmmorning.com/glCrop/common_ic_shiitake-mushroom.png",
  블루베리: "https://static.farmmorning.com/glCrop/common_ic_blueberry.png",
  양배추: "https://static.farmmorning.com/glCrop/common_ic_yangbaechu.png",
  자두: "https://static.farmmorning.com/glCrop/common_ic_plum.png",
  시금치: "https://static.farmmorning.com/glCrop/common_ic_spinach.png",
  매실: "https://static.farmmorning.com/glCrop/common_ic_japanese-apricot.png",
};

export const CROP_IMAGES_NAME = [
  "고추",
  "논벼",
  "감자",
  "콩",
  "토마토",
  "사과",
  "옥수수",
  "양파",
  "오이",
  "호박",
  "포도",
  "복숭아",
  "딸기",
  "감귤",
  "양배추",
  "배",
  "땅콩",
  "가지",
  "생강",
  "체리",
  "당근",
  "보리",
  "살구",
  "망고",
  "밀",
  "수수",
  "녹두",
  "완두",
  "유채",
  "담배",
];
