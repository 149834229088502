import { DISEASE_TYPE } from "@constants/name-table";
import axios from "axios";

export async function getDiseaseInfo(
  cropName: string,
  name: string,
  divCode: string,
): Promise<NcpmsSickResponse | NcpmsInsectResponse | NcpmsVirusResponse | null> {
  if (name && divCode && (cropName || divCode === DISEASE_TYPE.INSECT)) {
    let response = await axios.get(`/api/ncpms?korName=${name}&cropName=${cropName}&divCode=${divCode}`, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } else {
    return null;
  }
}
