import { PageProps } from "pages/_app";
import { checkCondition, checkVersion, QA_FLAG_DEV, QA_FLAG_STAGING } from "@vhows/util";
import EntrypointV1 from "@feature/entrypoint/entrypoint-v1";
import EntrypointV2 from "@feature/entrypoint/entrypoint-v2";
import EntrypointV3 from "@feature/entrypoint/entrypoint-v3";
import EntrypointV310 from "@feature/entrypoint/entrypoint-v3.1";
import EntrypointV320 from "@feature/entrypoint/entrypoint-v3.2";
import EntrypointV330 from "@feature/entrypoint/entrypoint-v3.3";
import packageJson from "package.json";
import { useGetFeatureFlags } from "@vhows/util";

function Entrypoint(_: PageProps) {
  const featureFlags = useGetFeatureFlags(packageJson.name);

  return checkCondition([
    [checkVersion("3.2.2", QA_FLAG_STAGING), <EntrypointV330 key={"EntrypointV330"} />],
    [checkVersion("3.2.0", QA_FLAG_STAGING), <EntrypointV320 key={"EntrypointV320"} />],
    [checkVersion("3.1.0", QA_FLAG_STAGING), <EntrypointV310 key={"EntrypointV310"} />],
    [checkVersion("3.0.0", QA_FLAG_STAGING), <EntrypointV3 key={"EntrypointV3"} />],
    [checkVersion("1.0.2", QA_FLAG_STAGING), <EntrypointV2 key={"EntrypointV2"} />],
    [<EntrypointV1 key={"EntrypointV1"} />],
  ]);
}

export default Entrypoint;
