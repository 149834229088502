import { ChangeEvent, useEffect, useCallback, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { PageProps } from "pages/_app";
import { throttle } from "lodash-es";
import { map, pipe, toArray, toAsync, concurrent } from "@fxts/core";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useAtom } from "jotai";
import {
  captureAtom,
  defaultMapCenter,
  loadingAtom,
  ncpmsDetailListAtom,
  ncpmsDetailVisibleAtom,
  ncpmsInfoAtom,
  pesticidesDataListAtom,
  plantixAtom,
  prevHistoryAtom,
} from "store/global";
import { COMMON, CROP_NAME } from "@constants/common";
import { nanoid } from "nanoid";
import {
  checkVersion,
  checkNull,
  QA_FLAG_STAGING,
  resizeImageFileWithObjectURL,
  setDataLayer,
  FindCoordinate,
  QA_FLAG_DEV,
  setSessionStorage,
  getSessionStorage,
} from "@vhows/util";
import CameraIcon from "components/icons/camera-icon";
import {
  DiseaseAnalysis,
  diseaseAnalysis,
  getDiseaseAnalysis,
  getERPAddress,
  getPresignedUrl,
  INDICATORS,
  logging,
  ncpmsDetail,
  ncpmsInfo,
  pesticideList,
  slackAlarm,
  uploadImageFile,
} from "service/vhows";
import { BlurringImage } from "ui";
import CropPopup from "./crop-popup";

function EntrypointV2(_: PageProps) {
  const [, setLoading] = useAtom(loadingAtom);
  const [, setCapture] = useAtom(captureAtom);
  const [, setResponseNcpmsInfo] = useAtom(ncpmsInfoAtom);
  const [, setNcpmsDetailList] = useAtom(ncpmsDetailListAtom);
  const [, setResponsePlantix] = useAtom(plantixAtom);
  const [, setPesticidesDataList] = useAtom(pesticidesDataListAtom);
  const [, setDetailVisible] = useAtom(ncpmsDetailVisibleAtom);
  const [prevHistory, setPrevHistory] = useAtom(prevHistoryAtom);
  const [capturePanelOpen, setCapturePanelOpen] = useState<boolean>(false);
  const [cropPopupOpen, setCropPopupOpen] = useState<boolean>(false);
  const [slideImgArr, setSlideImgArr] = useState<string[]>([]);
  const defaultCoordinate = { latitude: defaultMapCenter.lat, longitude: defaultMapCenter.lng };
  const router = useRouter();
  // 팜모닝및 ERP에서 넘어왔을 경우, 유저 아이디 정보
  let userId = router.query.uuid as string;
  let type = router.query.type as string;
  const district_1 = router.query.address1 as string;
  const district_2 = router.query.address2 as string;
  const district_3 = router.query.address3 as string;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    getPrevHistory();
  }, [router.isReady]);

  /**
   * 초기화
   */
  const init = () => {
    setLoading(false);
    setCapture("");
    setResponseNcpmsInfo([]);
    setResponsePlantix(null);
    setPesticidesDataList([]);
  };

  /**
   * 이전 진단 내역 조회 로직
   */
  const getPrevHistory = useCallback(
    throttle(
      async () => {
        if (checkVersion("2.1.0", QA_FLAG_STAGING)) {
          if (!type && !!getSessionStorage("entryType")) {
            type = getSessionStorage("entryType");
          }
        }
        // 외부 url 에서 접근시, router.query 정보 없을때
        if (!userId && router.asPath.indexOf("uuid") > -1) {
          const param = new URLSearchParams(window.location.search);
          userId = param.get("uuid") as string;
          if (!checkVersion("2.1.0", QA_FLAG_STAGING)) await router.replace("/");
        }
        const id = userId || localStorage.getItem(COMMON.USER_ID); // 팜모닝, ERP 유저 아이디
        const tid = localStorage.getItem(COMMON.TEMPORARY_ID); // 저장된 임시 아이디
        let result;
        if (id) {
          localStorage.setItem(COMMON.USER_ID, id);
          if (tid) {
            // 임시 아이디를 팜모닝 아이디와 동기화 처리 하지 않음
            result = await getDiseaseAnalysis(id, 99);
          } else {
            result = await getDiseaseAnalysis(id, 99);
          }
          await analysis(id);
        } else {
          if (tid) {
            // 비회원일 경우, 로컬스토리지의 임시정보로 이전내역 불러오기
            result = await getDiseaseAnalysis(tid, 99);
            await analysis(tid);
          } else {
            // 비회원일 경우, 임시아이디 발급 후 요청
            const tid = nanoid();
            localStorage.setItem(COMMON.TEMPORARY_ID, tid);
            result = await getDiseaseAnalysis(tid, 99);
            await analysis(tid);
          }
        }
        if (result && result.length > 0) {
          setPrevHistory(result);
        } else {
          setSlideImgArr([
            "/images/crop-slide-01.png",
            "/images/crop-slide-02.png",
            "/images/crop-slide-03.png",
            "/images/crop-slide-04.png",
            "/images/crop-slide-05.png",
            "/images/crop-slide-06.png",
            "/images/crop-slide-01.png",
            "/images/crop-slide-02.png",
          ]);
        }
        if (checkVersion("2.1.0", QA_FLAG_STAGING)) entryCheck();
      },
      500,
      { trailing: false },
    ),
    [userId],
  );

  const entryCheck = async () => {
    if (!!type && (type === "ws" || type === "as")) {
      // 우성,아산 ERP 진입
      setSessionStorage("entryType", type);
      const userKey = userId
        ? userId
        : localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
      try {
        const erpAddress = await getERPAddress(userKey as string, type);
        setSessionStorage("address", {
          district_1: erpAddress.district_1,
          district_2: erpAddress.district_2,
          district_3: erpAddress.district_3,
          latitude: erpAddress.latitude,
          longitude: erpAddress.longitude,
        });
      } catch (e) {
        console.log("ERPAddress API error", e);
      }
    } else {
      if (!!district_1 && !!district_2 && !!district_3) {
        // 팜모닝 진입 (주소 있는 회원)
        const coordinate = await FindCoordinate(district_1, district_2, district_3, defaultCoordinate);
        setSessionStorage("address", {
          district_1: district_1,
          district_2: district_2,
          district_3: district_3,
          latitude: coordinate.latitude,
          longitude: coordinate.longitude,
        });
      }
    }
    if (checkVersion("2.1.0", QA_FLAG_STAGING)) {
      await router.replace("/");
    }
  };

  /**
   * 로깅 관련 함수
   * @param id
   * 유저 아이디
   */
  const analysis = (id: string) => {
    logging(null, INDICATORS.ENTER, null, id);
    setDataLayer({ event: "pest_diagnosis_poc_pestDiagnosisMain_diagnosisMainScreen_Enter", user_uuid: id });
  };

  /**
   * 이미지 리사이즈
   * @param file
   * 이미지 파일
   */
  const resizeImage = async (file: File): Promise<File> => {
    const [resizedImageBase64, resizedImageFile] = await resizeImageFileWithObjectURL(file);
    setCapture(resizedImageBase64);
    return resizedImageFile;
  };

  /**
   * 파일 업로드 (사진 촬영)
   * @param e
   * 파일
   */
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setCapturePanelOpen(false);
    const files = e.target.files;
    if (files && files[0]) {
      init();
      setLoading(true);
      try {
        const userKey = localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
        if (userKey) {
          logging(null, INDICATORS.DIAGNOSIS, null, userKey);
          const file = await resizeImage(files[0]);
          const s3 = await getPresignedUrl(files[0].name);
          await uploadImageFile(s3.presignedUrl, file);
          const response = checkVersion("2.1.0", QA_FLAG_STAGING)
            ? await diseaseAnalysis(s3.cdnUrl, file, false, userKey, getSessionStorage("address"))
            : await diseaseAnalysis(s3.cdnUrl, file, false, userKey);
          setResponsePlantix(response);
          // 정상 응답인 경우에도 에러 띄워야 하는 케이스에 대해서 확인
          logging(null, INDICATORS.DIAGNOSIS_SUCCESS, null, userKey);
          if (response.apiStatus === "999") {
            await router.push("/error");
          } else {
            await ncpmsFunc(response);
            // 슬랙 알람
            if (userKey) {
              try {
                slackAlarm(response.id, userKey);
              } catch (e) {
                console.log("슬랙 알람 api 호출 오류 : ", e);
              }
            }
            await router.push("/result");
          }
        } else {
          throw new Error("사용자가 존재하지 않습니다.");
        }
      } catch (e: any) {
        if (e.response?.data && "code" in e.response.data) setResponsePlantix(e.response.data);
        else setResponsePlantix(null);
        await router.push("/error");
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * 농진청 데이터 호출 함수들
   */
  const ncpmsFunc = async (response: DiseaseAnalysis) => {
    await loadNcpms(response);
    if (checkVersion("2.0.0", QA_FLAG_STAGING)) await loadPesticidesList(response);
  };

  /**
   * 농진청 API 호출 관련 함수
   * @param plantixResponse
   * plantix 응답값
   */
  const loadNcpms = async (plantixResponse: DiseaseAnalysis) => {
    const values: number[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => ncpmsInfo(v ? v.peatId : "", v ? v.crops[0].toUpperCase() : "")),
      concurrent(3),
      toArray,
    );
    setResponseNcpmsInfo(values);

    if (checkVersion("2.0.0", QA_FLAG_STAGING)) {
      const detailValues = await pipe(
        values,
        toAsync,
        map(v => {
          return v > 0 ? ncpmsDetail(v) : { ncpmsId: -1, cropName: "", serviceCode: "", divCode: "", title: "" };
        }),
        concurrent(3),
        toArray,
      );
      setNcpmsDetailList(detailValues);

      const buttonVisibleValues: boolean[] = await pipe(
        detailValues,
        map(v => {
          if (v.ncpmsId > 0) {
            const detail = v as NcpmsDetailResponse;
            if (detail.divCode === "NP01") {
              return checkNull({
                value: [
                  detail.styleInfo,
                  detail.ecologyInfo,
                  detail.developmentCondition,
                  detail.symptoms,
                  detail.preventionMethod,
                ],
              });
            } else if (v.divCode === "NP02") {
              return !!detail.virusCharacteristic;
            } else if (v.divCode === "NP03" || v.divCode === "NP04") {
              return checkNull({
                value: [
                  detail.styleInfo,
                  detail.ecologyInfo,
                  detail.developmentCondition,
                  detail.damageInfo,
                  detail.preventionMethod,
                ],
              });
            }
          }
          return false;
        }),
        toArray,
      );
      setDetailVisible(buttonVisibleValues);
    }
  };

  /**
   * 추천 농약 리스트 호출 관련 함수
   */
  const loadPesticidesList = async (plantixResponse: DiseaseAnalysis) => {
    const values: PesticidesDataList = [];
    const pesticidesArr: PesticidesList[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => pesticideList(11, 0, v.diseaseName, v[CROP_NAME][0])),
      concurrent(3),
      toArray,
    );
    pipe(
      pesticidesArr,
      map(v => values.push(v.data)),
      toArray,
    );
    setPesticidesDataList(values);
  };

  /**
   * 작물 종류 팝업 on, off
   */
  const cropPopupClick = () => {
    setCropPopupOpen(!cropPopupOpen);
  };

  /**
   * 사진 촬영 하단 판넬 on, off
   */
  const photoBtnClick = () => {
    setCapturePanelOpen(!capturePanelOpen);
  };

  /**
   * 이전 진단 내역 이미지 클릭 함수
   * @param index
   * 이미지 인덱스
   */
  const imageClick = (index: number) => async () => {
    setLoading(true);
    try {
      const userKey = localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
      if (userKey) logging(null, INDICATORS.PREV_IMAGE_CLICK, null, userKey);
      const response = prevHistory[index];
      setCapture(response.imgUrl);
      setResponsePlantix(response);
      await ncpmsFunc(response);
      await router.push("/result");
    } catch (e) {
      console.log("error: ", e);
      setLoading(false);
      await router.push("/error");
    }
  };

  /**
   * 이전 진단 내역 영역 구성 로직
   */
  const prevDiagnosisImg = () => {
    let index = 0;
    return (
      <>
        <div className="mb-2 grid grid-cols-3 gap-2">
          {pipe(
            prevHistory,
            map(v => {
              return (
                <button
                  key={v.imgUrl}
                  id="previousResultItem"
                  className=""
                  data-gtm="pest_diagnosis_poc_pestDiagnosisMain_previousResultItem_Click"
                  onClick={imageClick(index++)}
                >
                  <BlurringImage
                    className="rounded-xl object-cover"
                    src={v.imgUrl}
                    layout="responsive"
                    priority={true}
                    width={"100%"}
                    height={"100%"}
                    sizes="(min-width: 576px) 176px, 30vw"
                    alt="prevImg"
                  />
                </button>
              );
            }),
            toArray,
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="relative">
        {/*작물 확인 팝업*/}
        {cropPopupOpen && <CropPopup cropPopupClick={() => cropPopupClick()}></CropPopup>}

        {/*메인페이지 전용 가이드 영역*/}
        <div className="px-4 pt-5">
          <div className="text-2xl font-bold">
            병해충을 진단하고
            <br />
            정확한 농약을 확인하세요
          </div>
          <div className="pt-2.5 text-[15px] font-normal">
            어떤 농약을 써야할 지 고민되신다면,
            <br />
            아래 버튼을 눌러 작물을 촬영하고 진단 받아보세요.
          </div>
        </div>

        {/*이전 진단 내역 영역*/}
        {prevHistory.length > 0 ? (
          <div className="px-4">
            <div
              className="flex cursor-pointer flex-row pt-4 font-medium text-green-500"
              id="viewSupportedCropsButton"
              data-gtm="pest_diagnosis_poc_pestDiagnosisMain_viewSupportedCropsButton_Click"
              onClick={cropPopupClick}
            >
              <span className="">{"30개 작물 확인하기"}</span>
              <ChevronRightIcon className="w-5" />
            </div>
            {/*타이틀*/}
            <div className="mt-[70px] mb-2.5 flex flex-wrap items-center">
              <h2 className="text-lg font-bold leading-6">{"이전 진단 내역"}</h2>
            </div>
            <div id="prevHistory">{prevDiagnosisImg()}</div>

            {/*버튼영역 회피를 위한 더미 영역*/}
            <div className="h-[136px]"></div>
          </div>
        ) : (
          <>
            <div className="slide-image flex w-full flex-row overflow-hidden">
              {slideImgArr.map((v: string, index: number) => {
                return (
                  <div key={index} className="active mr-5 w-full min-w-[60%] animate-scroll">
                    <Image
                      src={v}
                      priority={true}
                      layout="responsive"
                      sizes="(min-width: 576px) 350px, 60vw"
                      width={"100%"}
                      height={"100%"}
                      alt={v}
                    />
                  </div>
                );
              })}
            </div>
            <div id="prevHistory" />
            <div
              className="fixed bottom-0 flex w-full max-w-xl cursor-pointer flex-row justify-center pb-[106px] font-medium text-green-500"
              id="viewSupportedCropsButton"
              data-gtm="pest_diagnosis_poc_pestDiagnosisMain_viewSupportedCropsButton_Click"
              onClick={cropPopupClick}
            >
              <span className="">{"30개 작물 확인하기"}</span>
              <div>
                <ChevronRightIcon className="w-5" />
              </div>
            </div>
          </>
        )}

        {/*촬영하기 버튼*/}
        <div className="fixed bottom-0 w-full max-w-xl rounded-xl">
          <div className="h-5 w-full bg-gradient-to-t from-white to-transparent" />
          <div className="bg-white px-5 text-center">
            <button
              className="mb-5 h-14 w-full rounded-xl bg-[#12B564] text-white"
              id="takePhotoButton"
              data-gtm="pest_diagnosis_poc_pestDiagnosisMain_takePhotoButton_Click"
              onClick={photoBtnClick}
            >
              <div className="flex items-center justify-center">
                <div className="h-5 w-5">
                  <CameraIcon />
                </div>
                <span className="ml-3">사진 촬영</span>
              </div>
            </button>
          </div>
        </div>

        <div className={`fixed bottom-0 ${!capturePanelOpen ? "w-0" : "z-50 w-full max-w-xl"}`}>
          <div className={`relative`}>
            {capturePanelOpen && (
              <div className="fixed inset-0 overflow-y-auto bg-gray-500 opacity-80" onClick={photoBtnClick}></div>
            )}
            <div className={`relative h-[145px] ${!capturePanelOpen ? "overflow-y-hidden" : "z-50"}`}>
              <div
                id="capturePanel"
                className={`-bottom-[172px] h-[172px] w-full max-w-xl items-center rounded-t-xl bg-white px-5 py-[30px] ${
                  !capturePanelOpen
                    ? "translate-y-40"
                    : "z-50 -translate-y-[26px] transition-transform delay-100 duration-500"
                }`}
              >
                <div>
                  <label
                    htmlFor="takePhotoMenu"
                    id="takePhotoMenuLabel"
                    className="flex-cols flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/camera2.svg"}
                      width={24}
                      height={24}
                      alt="cameraIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">사진 촬영</div>
                    <input
                      type="file"
                      id="takePhotoMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_takePhotoMenu_Click"
                      capture
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                  <label
                    htmlFor="albumMenu"
                    id="albumMenuLabel"
                    className="flex-cols mt-2 flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/album.svg"}
                      width={24}
                      height={24}
                      alt="albumIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">앨범 선택</div>
                    <input
                      type="file"
                      id="albumMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_selectFileMenu_Click"
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EntrypointV2;
