import { ChangeEvent, useEffect, useCallback, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { PageProps } from "pages/_app";
import { throttle } from "lodash-es";
import { map, pipe, toArray, toAsync, concurrent } from "@fxts/core";
import { ArrowRightIcon, LightBulbIcon } from "@heroicons/react/24/outline";
import { useAtom } from "jotai";
import { NAME_TABLE } from "@constants/name-table";
import { captureAtom, loadingAtom, ncpmsAtom, ncpmsInfoAtom, plantixAtom, prevHistoryAtom } from "store/global";
import { COMMON } from "@constants/common";
import { nanoid } from "nanoid";
import { resizeImageFileWithObjectURL, setDataLayer } from "@vhows/util";
import { getDiseaseInfo } from "service/serverless";
import CameraIcon from "components/icons/camera-icon";
import MedicineIcon from "components/icons/medicine-icon";
import {
  Diagnoses,
  DiseaseAnalysis,
  diseaseAnalysis,
  getDiseaseAnalysis,
  getPresignedUrl,
  INDICATORS,
  logging,
  ncpmsIdUpdate,
  slackAlarm,
  uploadImageFile,
} from "service/vhows";

function EntrypointV1(_: PageProps) {
  const [, setLoading] = useAtom(loadingAtom);
  const [, setCapture] = useAtom(captureAtom);
  const [, setResponseNcpms] = useAtom(ncpmsAtom);
  const [, setResponsePlantix] = useAtom(plantixAtom);
  const [prevHistory, setPrevHistory] = useAtom(prevHistoryAtom);
  const [capturePanelOpen, setCapturePanelOpen] = useState<boolean>(false);
  const router = useRouter();
  // 팜모닝에서 넘어왔을 경우, 유저 아이디 정보
  let userId = router.query.uuid as string;

  useEffect(() => {
    init();
    getPrevHistory();
  }, []);

  /**
   * 초기화
   */
  const init = () => {
    setLoading(false);
    setCapture("");
    setResponseNcpms([]);
    setResponsePlantix(null);
  };

  /**
   * 로깅 관련 함수
   * @param id
   * 유저 아이디
   */
  const analysis = (id: string) => {
    logging(null, INDICATORS.ENTER, null, id);
    setDataLayer({ userId: id });
  };

  /**
   * 이전 진단 내역 조회 로직
   */
  const getPrevHistory = useCallback(
    throttle(
      async () => {
        // 외부 url 에서 접근시, router.query 정보 없을때
        if (!userId && router.asPath.indexOf("uuid") > -1) {
          const param = new URLSearchParams(window.location.search);
          userId = param.get("uuid") as string;
          await router.replace("/");
        }
        const id = userId || localStorage.getItem(COMMON.USER_ID); // 팜모닝 유저 아이디
        const tid = localStorage.getItem(COMMON.TEMPORARY_ID); // 저장된 임시 아이디
        let result;
        if (id) {
          localStorage.setItem(COMMON.USER_ID, id);
          if (tid) {
            // 임시 아이디를 팜모닝 아이디와 동기화 처리 하지 않음
            result = await getDiseaseAnalysis(id);
          } else {
            result = await getDiseaseAnalysis(id);
          }
          await analysis(id);
        } else {
          if (tid) {
            // 비회원일 경우, 로컬스토리지의 임시정보로 이전내역 불러오기
            result = await getDiseaseAnalysis(tid);
            await analysis(tid);
          } else {
            // 비회원일 경우, 임시아이디 발급 후 요청
            const tid = nanoid();
            localStorage.setItem(COMMON.TEMPORARY_ID, tid);
            result = await getDiseaseAnalysis(tid);
            await analysis(tid);
          }
        }
        if (result && result.length > 0) {
          setPrevHistory(result);
        }
      },
      500,
      { trailing: false },
    ),
    [userId],
  );

  /**
   * 이미지 리사이즈
   * @param file
   * 이미지 파일
   */
  const resizeImage = async (file: File): Promise<File> => {
    const [resizedImageBase64, resizedImageFile] = await resizeImageFileWithObjectURL(file);
    setCapture(resizedImageBase64);
    return resizedImageFile;
  };

  /**
   * 파일 업로드 (사진 촬영)
   * @param e
   * 파일
   */
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setCapturePanelOpen(false);
    const files = e.target.files;
    if (files && files[0]) {
      init();
      setLoading(true);
      try {
        const userKey = localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
        if (userKey) {
          logging(null, INDICATORS.DIAGNOSIS, null, userKey);
          const file = await resizeImage(files[0]);
          const s3 = await getPresignedUrl(files[0].name);
          await uploadImageFile(s3.presignedUrl, file);
          const response = await diseaseAnalysis(s3.cdnUrl, file, false, userKey);
          setResponsePlantix(response);
          // 정상 응답인 경우에도 에러 띄워야 하는 케이스에 대해서 확인
          logging(null, INDICATORS.DIAGNOSIS_SUCCESS, null, userKey);
          if (response.apiStatus === "999") {
            await router.push("/error");
          } else {
            await loadNcpms(response);
            await router.push("/result");
          }
        } else {
          throw new Error("사용자가 존재하지 않습니다.");
        }
      } catch (e: any) {
        if (e.response?.data && "code" in e.response.data) setResponsePlantix(e.response.data);
        else setResponsePlantix(null);
        await router.push("/error");
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * 농진청 API 호출 관련 함수
   * @param plantixResponse
   * plantix 응답값
   */
  const loadNcpms = async (plantixResponse: DiseaseAnalysis) => {
    const ncpmsUpdateArray: NcpmsUpdateArray[] = [];
    const slackAlarmMappingArray: SlackAlarmMappingArray[] = [];
    const userKey = localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
    const values: (NcpmsSickResponse | NcpmsInsectResponse | NcpmsVirusResponse | null)[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => {
        const crop = v.crops.find(crop => !!NAME_TABLE[`${v.peatId}+${crop.toUpperCase()}`]);
        if (crop && v.peatId !== "999999")
          ncpmsUpdateArray.push({ id: v.id, ncpmsId: `${v.peatId}+${crop.toUpperCase()}` });
        return crop ? NAME_TABLE[`${v.peatId}+${crop.toUpperCase()}`] : null;
      }),
      map(v => getDiseaseInfo(v ? v.ncpmsCropName : "", v ? v.name : "", v ? v.ncpmsDivCode : "")),
      concurrent(3),
      toArray,
    );
    setResponseNcpms(values);

    // ncpmsId 업데이트
    if (ncpmsUpdateArray.length > 0 && userKey) {
      try {
        pipe(
          ncpmsUpdateArray,
          toAsync,
          map(v => ncpmsIdUpdate(plantixResponse.id, v.id, v.ncpmsId, userKey)),
          concurrent(3),
          toArray,
        );
      } catch (e) {
        console.log("ncpmsID 코드 업데이트 실패 : ", e);
      }
    }

    // 슬랙 알람
    if (userKey) {
      try {
        plantixResponse.predictedDiagnoses.map((diagnose: Diagnoses, index: number) => {
          let crop = diagnose.crops.find(crop => !!NAME_TABLE[`${diagnose.peatId}+${crop.toUpperCase()}`]);
          crop = crop ? crop : "";
          const content = NAME_TABLE[`${diagnose.peatId}+${crop.toUpperCase()}`];
          // if (values[index] !== null) {
          const value = values[index] as NcpmsSickResponse | NcpmsInsectResponse | NcpmsVirusResponse;
          slackAlarmMappingArray.push({
            id: diagnose.id,
            cropName: content.cropName,
            diseaseName: content.name,
            ncpmsUrl: value?.webUrl,
          });
          // }
        });
        slackAlarm(plantixResponse.id, userKey, slackAlarmMappingArray);
      } catch (e) {
        console.log("슬랙 알람 api 호출 오류 : ", e);
      }
    }
  };

  /**
   * 사진 촬영 하단 판넬 on, off
   */
  const photoBtnClick = () => {
    setCapturePanelOpen(!capturePanelOpen);
  };

  /**
   * 이전 진단 내역 이미지 클릭 함수
   * @param index
   * 이미지 인덱스
   */
  const imageClick = (index: number) => async () => {
    setLoading(true);
    try {
      const userKey = localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
      if (userKey) logging(null, INDICATORS.PREV_IMAGE_CLICK, null, userKey);
      const response = prevHistory[index];
      setCapture(response.imgUrl);
      setResponsePlantix(response);
      await loadNcpms(response);
      await router.push("/result");
    } catch (e) {
      console.log("error: ", e);
      setLoading(false);
      await router.push("/error");
    }
  };

  /**
   * 이전 진단 내역 영역 구성 로직
   */
  const prevDiagnosisImg = () => {
    let index = 0;
    return (
      <div className="grid grid-cols-3 gap-3">
        {pipe(
          prevHistory,
          map(v => {
            return (
              <button
                key={index++}
                id="previousResultItem"
                className=""
                data-gtm="pest_diagnosis_poc_pestDiagnosisMain_previousResultItem_Click"
                onClick={imageClick(index)}
              >
                <Image
                  className="rounded-md object-cover"
                  src={v.imgUrl}
                  layout="responsive"
                  width={"100%"}
                  height={"100%"}
                  alt={"prevImg"}
                />
              </button>
            );
          }),
          toArray,
        )}
      </div>
    );
  };

  return (
    <>
      <div className="relative">
        <div className="px-5 pt-5">
          {/*메인페이지 전용 가이드 영역*/}
          {/*타이틀*/}
          <div className="mb-4 flex flex-wrap items-center">
            <h2 className="text-lg font-bold leading-6">{"🌱 병해충 진단 사용방법"}</h2>
          </div>
          {/*아이콘 그룹*/}
          <div className="flex flex-wrap rounded-xl bg-[#F9F9F9]">
            <div className="mx-[7%] grid w-full grid-cols-5">
              <div className="relative mt-7 mb-3 self-center text-center">
                <Image src={"/icons/rectangle.svg"} width={56} height={56} loading="eager" alt="rectangle" />
                <div className="absolute top-[11px] left-[calc(50%_-_16px)] h-[33px] w-[33px]">
                  <CameraIcon />
                </div>
              </div>
              <div className="mt-7 mb-3 self-center justify-self-center text-center">
                <ArrowRightIcon className="h-4 w-4 text-[#7FD68B]" />
              </div>
              <div className="relative mt-7 mb-3 self-center text-center">
                <Image src={"/icons/rectangle.svg"} width={56} height={56} loading="eager" alt="rectangle" />
                <div className="absolute top-3 left-[calc(50%_-_16px)]">
                  <LightBulbIcon className="h-8 w-8 text-white" />
                </div>
              </div>
              <div className="mt-7 mb-3 self-center justify-self-center text-center">
                <ArrowRightIcon className="h-4 w-4 text-[#7FD68B]" />
              </div>
              <div className="relative mt-7 mb-3 self-center text-center">
                <Image src={"/icons/rectangle.svg"} width={56} height={56} loading="eager" alt="rectangle" />
                <div className="absolute top-[11px] left-[calc(50%_-_16px)] h-[34px] w-[33px]">
                  <MedicineIcon />
                </div>
              </div>
            </div>
            {/*설명*/}
            <div className="mx-[calc(2%_+_5px)] mb-7 grid w-full grid-cols-3 gap-[calc(7%_+_1px)]">
              <div className={`pr-2 text-center text-sm font-medium text-zinc-500`}>
                병해충 부분을
                <br />
                촬영하세요.
              </div>
              <div className={`text-center text-sm font-medium text-zinc-500`}>
                어떤 문제인지
                <br />
                바로 확인하세요.
              </div>
              <div className={`pl-2 text-center text-sm font-medium text-zinc-500`}>
                처방으로
                <br />
                조치하세요.
              </div>
            </div>
            <div className="flex-1"></div>
          </div>

          {/*이전 진단 내역 영역*/}
          <div id="prevHistory">
            {prevHistory.length > 0 ? (
              <>
                {/*타이틀*/}
                <div className="mt-5 mb-4 flex flex-wrap items-center">
                  <h2 className="text-lg font-bold leading-6">{"이전 진단 내역"}</h2>
                </div>
                {prevDiagnosisImg()}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/*촬영하기 버튼*/}
        <div className="fixed bottom-10 z-40 m-auto w-full max-w-xl">
          <label className="r-10 absolute right-5 bottom-[5%] z-40 h-20 w-20 cursor-pointer rounded-lg">
            <div
              className="relative self-center text-center"
              id="takePhotoButton"
              data-gtm="community_postList_optionsButton_Click"
              onClick={photoBtnClick}
            >
              <Image src={"/icons/rectangle2.svg"} width={80} height={80} loading="eager" alt="rectangle" />
              <div className="absolute top-3 left-[calc(50%_-_17px)] h-[31px] w-[31px]">
                <CameraIcon />
              </div>

              <div className="absolute top-1 left-[18px] pt-8 text-center">
                <span className="text-xs font-semibold text-white">촬영하기</span>
              </div>
            </div>
          </label>
        </div>

        <div className={`fixed bottom-0 ${!capturePanelOpen ? "" : "z-50 w-full max-w-xl"}`}>
          <div className={`relative`}>
            {capturePanelOpen && (
              <div className="fixed inset-0 overflow-y-auto bg-gray-500 opacity-80" onClick={photoBtnClick}></div>
            )}
            <div className={`relative h-[145px] ${!capturePanelOpen ? "overflow-y-hidden" : "z-50"}`}>
              <div
                id="capturePanel"
                className={`-bottom-[172px] h-[172px] w-full max-w-xl items-center rounded-t-xl bg-white px-5 py-[30px] ${
                  !capturePanelOpen
                    ? "translate-y-40"
                    : "delay-50 z-50 -translate-y-[26px] transition-transform duration-500"
                }`}
              >
                <div>
                  <label
                    htmlFor="takePhotoMenu"
                    id="takePhotoMenuLabel"
                    className="flex-cols flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/camera2.svg"}
                      width={24}
                      height={24}
                      alt="cameraIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">사진 촬영</div>
                    <input
                      type="file"
                      id="takePhotoMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_takePhotoMenu_Click"
                      capture
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                  <label
                    htmlFor="albumMenu"
                    id="albumMenuLabel"
                    className="flex-cols mt-2 flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/album.svg"}
                      width={24}
                      height={24}
                      alt="albumIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">앨범 선택</div>
                    <input
                      type="file"
                      id="albumMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_albumMenu_Click"
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EntrypointV1;
