import { ChangeEvent, useEffect, useCallback, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { PageProps } from "pages/_app";
import { throttle } from "lodash-es";
import { map, pipe, toArray, toAsync, concurrent } from "@fxts/core";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useAtom } from "jotai";
import {
  captureAtom,
  mapCenterAtom,
  loadingAtom,
  ncpmsDetailListAtom,
  ncpmsDetailVisibleAtom,
  ncpmsInfoAtom,
  pesticidesDataListAtom,
  plantixAtom,
  prevHistoryAtom,
  defaultMapCenter,
  mapAddressAtom,
  mapZoomLevelAtom,
  bottomSheetSnapAtom,
  moveMapCenterAtom,
  resultPageSourceAtom,
  myResultListAtom,
  allResultListAtom,
  bottomSheetDataAtom,
  allResultListCropFilterAtom,
  allResultListCropFilterLabelAtom,
  sortOrderAtom,
} from "store/global";
import { COMMON, CROP_NAME } from "@constants/common";
import { nanoid } from "nanoid";
import {
  checkVersion,
  checkNull,
  QA_FLAG_STAGING,
  resizeImageFileWithObjectURL,
  setDataLayer,
  FindCoordinate,
  Location,
  MapLoader,
  setSessionStorage,
  getSessionStorage,
  getLocalStorage,
  setLocalStorage,
} from "@vhows/util";
import {
  checkTodayCount,
  DiseaseAnalysis,
  diseaseAnalysis,
  diseaseListCoordinate,
  getDiseaseAnalysis,
  getERPAddress,
  getPresignedUrl,
  INDICATORS,
  logging,
  ncpmsDetail,
  ncpmsInfo,
  pesticideList,
  slackAlarm,
  uploadImageFile,
} from "service/vhows";
import { BlurringImage, BottomFloatingButton } from "ui";
import CropPopup from "./crop-popup";
import { ROUTE } from "@constants/route";
import TouchIcon from "components/icons/touch-icon";
import CameraIconV2 from "components/icons/camera-icon-v2";
import { IconContainer } from "components/icons/icon-container";

function EntrypointV3(_: PageProps) {
  const [, setSource] = useAtom(resultPageSourceAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [, setCapture] = useAtom(captureAtom);
  const [, setResponseNcpmsInfo] = useAtom(ncpmsInfoAtom);
  const [, setNcpmsDetailList] = useAtom(ncpmsDetailListAtom);
  const [, setResponsePlantix] = useAtom(plantixAtom);
  const [, setPesticidesDataList] = useAtom(pesticidesDataListAtom);
  const [, setDetailVisible] = useAtom(ncpmsDetailVisibleAtom);
  const [prevHistory, setPrevHistory] = useAtom(prevHistoryAtom);
  const [mapCenter, setMapCenter] = useAtom(mapCenterAtom);
  const [, setMapAddress] = useAtom(mapAddressAtom);
  const [, setPrevMapCenter] = useAtom(moveMapCenterAtom);
  const [, setCurrentZoomLevel] = useAtom(mapZoomLevelAtom); // 현재 지도 줌 레벨
  const [, setSnap] = useAtom(bottomSheetSnapAtom); // 바텀 시트 확장 높이
  const [, setDataListMy] = useAtom(myResultListAtom);
  const [, setDataListAll] = useAtom(allResultListAtom);
  const [, setDataListBottom] = useAtom(bottomSheetDataAtom); // 바텀 시트 데이터 배열
  const [, setSortCrop] = useAtom(allResultListCropFilterAtom);
  const [, setSortCropLabel] = useAtom(allResultListCropFilterLabelAtom);
  const [, setSortOrder] = useAtom(sortOrderAtom);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [capturePanelOpen, setCapturePanelOpen] = useState<boolean>(false);
  const [cropPopupOpen, setCropPopupOpen] = useState<boolean>(false);
  const [todayCount, setTodayCount] = useState<number>(0);
  const [toastVisible, setToastVisible] = useState<boolean>(true);
  const defaultCoordinate = { latitude: defaultMapCenter.lat, longitude: defaultMapCenter.lng };
  const router = useRouter();
  // 팜모닝및 ERP에서 넘어왔을 경우, 유저 아이디 정보
  let userId = router.query.uuid as string;
  let type = router.query.type as string;
  const district_1 = router.query.address1 as string;
  const district_2 = router.query.address2 as string;
  const district_3 = router.query.address3 as string;

  useEffect(() => {
    init();
    getTodayCount();
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    getPrevHistory();
  }, [router.isReady]);

  /**
   * 초기화
   */
  const init = () => {
    setLoading(false);
    setCapture("");
    setResponseNcpmsInfo([]);
    setResponsePlantix(null);
    setPesticidesDataList([]);
    setSessionStorage("bottom_sheet_scroll_" + ROUTE.CROPMAP, 0);
    setDataListMy([]);
    setDataListAll([]);
    setDataListBottom([]);
    // setSortCrop([]);
    // setSortCropLabel([]);
  };

  /**
   * 오늘 신규 진단 건수 조회
   */
  const getTodayCount = async () => {
    const count = await checkTodayCount();
    setTodayCount(count);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  /**
   * 이전 진단 내역 조회 로직
   */
  const getPrevHistory = useCallback(
    throttle(
      async () => {
        if (!type && !!getSessionStorage("entryType")) {
          type = getSessionStorage("entryType");
        }
        // 외부 url 에서 접근시, router.query 정보 없을때
        if (!userId && router.asPath.indexOf("uuid") > -1) {
          const param = new URLSearchParams(window.location.search);
          userId = param.get("uuid") as string;
        }
        const id = userId || getLocalStorage(COMMON.USER_ID); // 팜모닝 유저 아이디
        const tid = getLocalStorage(COMMON.TEMPORARY_ID); // 저장된 임시 아이디
        let result;
        if (id) {
          setLocalStorage(COMMON.USER_ID, id);
          if (tid) {
            result = await getDiseaseAnalysis(id, 11);
          } else {
            result = await getDiseaseAnalysis(id, 11);
          }
          await analysis(id);
        } else {
          if (tid) {
            // 비회원일 경우, 로컬스토리지의 임시정보로 이전내역 불러오기
            result = await getDiseaseAnalysis(tid, 11);
            await analysis(tid);
          } else {
            // 비회원일 경우, 임시아이디 발급 후 요청
            const tid = nanoid();
            setLocalStorage(COMMON.TEMPORARY_ID, tid);
            result = await getDiseaseAnalysis(tid, 11);
            await analysis(tid);
          }
        }
        if (result && result.length > 0) {
          setPrevHistory(result);
        }
        await entryCheck();
        await getLocations();
      },
      500,
      { trailing: false },
    ),
    [userId],
  );

  const entryCheck = async () => {
    if (!!type && (type === "ws" || type === "as")) {
      // 우성,아산 ERP 진입
      setSessionStorage("entryType", type);
      const userKey = userId ? userId : getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
      try {
        const erpAddress = await getERPAddress(userKey as string, type);
        setMapCenter({ lat: erpAddress.latitude, lng: erpAddress.longitude });
        setMapAddress({
          district_1: erpAddress.district_1,
          district_2: erpAddress.district_2,
          district_3: erpAddress.district_3,
        });
        setSessionStorage("address", {
          district_1: erpAddress.district_1,
          district_2: erpAddress.district_2,
          district_3: erpAddress.district_3,
          latitude: erpAddress.latitude,
          longitude: erpAddress.longitude,
        });
      } catch (e) {
        console.log("ERPAddress API error", e);
      }
    } else {
      if (!!district_1 && !!district_2 && !!district_3) {
        // 팜모닝 진입 (주소 있는 회원)
        const center = await FindCoordinate(district_1, district_2, district_3, defaultCoordinate);
        setMapCenter({ lat: center.latitude, lng: center.longitude });
        setMapAddress({ district_1: district_1, district_2: district_2, district_3: district_3 });
        setSessionStorage("address", {
          district_1: district_1,
          district_2: district_2,
          district_3: district_3,
          latitude: center.latitude,
          longitude: center.longitude,
        });
      }
    }
    if (!getSessionStorage("address")) {
      setSortOrder("LATEST");
    }
    setMapLoaded(true);
    await router.replace({
      pathname: "/",
      // query: {
      //   address1: district_1,
      //   address2: district_2,
      //   address3: district_3,
      // },
    });
  };

  const getLocations = async () => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    if (userKey) {
      const data = await diseaseListCoordinate(mapCenter.lat, mapCenter.lng, userKey, 100, 6, null);
      setLocations(data);
    }
  };

  /**
   * 로깅 관련 함수
   * @param id
   * 유저 아이디
   */
  const analysis = (id: string) => {
    // test();
    logging(null, INDICATORS.ENTER, null, id);
    setDataLayer({ event: "pest_diagnosis_poc_pestDiagnosisMain_diagnosisMainScreen_Enter", user_uuid: id });
  };

  /**
   * 이미지 리사이즈
   * @param file
   * 이미지 파일
   */
  const resizeImage = async (file: File): Promise<File> => {
    const [resizedImageBase64, resizedImageFile] = await resizeImageFileWithObjectURL(file);
    setCapture(resizedImageBase64);
    return resizedImageFile;
  };

  /**
   * 파일 업로드 (사진 촬영)
   * @param e
   * 파일
   */
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setCapturePanelOpen(false);
    const files = e.target.files;
    if (files && files[0]) {
      init();
      setLoading(true);
      try {
        const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
        if (userKey) {
          logging(null, INDICATORS.DIAGNOSIS, null, userKey);
          const file = await resizeImage(files[0]);
          const s3 = await getPresignedUrl(files[0].name);
          await uploadImageFile(s3.presignedUrl, file);
          const userType = getSessionStorage("entryType") || type;
          const response = await diseaseAnalysis(
            s3.cdnUrl,
            file,
            false,
            userKey,
            getSessionStorage("address"),
            userType,
          );
          setResponsePlantix(response);
          // 정상 응답인 경우에도 에러 띄워야 하는 케이스에 대해서 확인
          logging(null, INDICATORS.DIAGNOSIS_SUCCESS, null, userKey);
          if (response.apiStatus === "999") {
            await router.push("/error");
          } else {
            await ncpmsFunc(response);
            // 슬랙 알람
            if (userKey) {
              try {
                slackAlarm(response.id, userKey);
              } catch (e) {
                console.log("슬랙 알람 api 호출 오류 : ", e);
              }
            }
            setSource("fromTakePhoto");
            await router.push(`/result/${response.traceId}`);
          }
        } else {
          throw new Error("사용자가 존재하지 않습니다.");
        }
      } catch (e: any) {
        if (e.response?.data && "code" in e.response.data) setResponsePlantix(e.response.data);
        else setResponsePlantix(null);
        await router.push("/error");
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * 농진청 데이터 호출 함수들
   */
  const ncpmsFunc = async (response: DiseaseAnalysis) => {
    await loadNcpms(response);
    await loadPesticidesList(response);
  };

  /**
   * 농진청 API 호출 관련 함수
   * @param plantixResponse
   * plantix 응답값
   */
  const loadNcpms = async (plantixResponse: DiseaseAnalysis) => {
    const values: number[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => ncpmsInfo(v ? v.peatId : "", v ? v.crops[0].toUpperCase() : "")),
      concurrent(3),
      toArray,
    );
    setResponseNcpmsInfo(values);

    const detailValues = await pipe(
      values,
      toAsync,
      map(v => {
        return v > 0 ? ncpmsDetail(v) : { ncpmsId: -1, cropName: "", serviceCode: "", divCode: "", title: "" };
      }),
      concurrent(3),
      toArray,
    );
    setNcpmsDetailList(detailValues);

    const buttonVisibleValues: boolean[] = await pipe(
      detailValues,
      map(v => {
        if (v.ncpmsId > 0) {
          const detail = v as NcpmsDetailResponse;
          if (detail.divCode === "NP01") {
            return checkNull({
              value: [
                detail.styleInfo,
                detail.ecologyInfo,
                detail.developmentCondition,
                detail.symptoms,
                detail.preventionMethod,
              ],
            });
          } else if (v.divCode === "NP02") {
            return !!detail.virusCharacteristic;
          } else if (v.divCode === "NP03" || v.divCode === "NP04") {
            return checkNull({
              value: [
                detail.styleInfo,
                detail.ecologyInfo,
                detail.developmentCondition,
                detail.damageInfo,
                detail.preventionMethod,
              ],
            });
          }
        }
        return false;
      }),
      toArray,
    );
    setDetailVisible(buttonVisibleValues);
  };

  /**
   * 추천 농약 리스트 호출 관련 함수
   */
  const loadPesticidesList = async (plantixResponse: DiseaseAnalysis) => {
    const values: PesticidesDataList = [];
    const pesticidesArr: PesticidesList[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => pesticideList(11, 0, v.diseaseName, v[CROP_NAME][0])),
      concurrent(3),
      toArray,
    );
    pipe(
      pesticidesArr,
      map(v => values.push(v.data)),
      toArray,
    );
    setPesticidesDataList(values);
  };

  /**
   * 작물 종류 팝업 on, off
   */
  const cropPopupClick = () => {
    setCropPopupOpen(!cropPopupOpen);
  };

  /**
   * 사진 촬영 하단 판넬 on, off
   */
  const photoBtnClick = () => {
    setCapturePanelOpen(!capturePanelOpen);
  };

  /**
   * 이전 진단 내역 이미지 클릭 함수
   * @param index
   * 이미지 인덱스
   */
  const imageClick = (index: number) => async () => {
    setLoading(true);
    try {
      const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
      if (userKey) logging(null, INDICATORS.PREV_IMAGE_CLICK, null, userKey);
      const response = prevHistory[index];
      setCapture(response.imgUrl);
      setResponsePlantix(response);
      setSource("fromMyResult");
      await ncpmsFunc(response);
      await router.push(`/result/${response.traceId}`);
    } catch (e) {
      console.log("error: ", e);
      setLoading(false);
      await router.push("/error");
    }
  };

  /**
   * 이전 진단 내역 영역 구성 로직
   */
  const prevDiagnosisImg = () => {
    let index = 0;
    return (
      <>
        <div className="flex min-w-max flex-nowrap">
          {prevHistory.map((v: DiseaseAnalysis, index: number) => {
            const date = new Date(v.createDate);
            if (index < 10) {
              return (
                <div key={v.imgUrl} className="first:ml-4 last:mr-4 mr-2 overflow-hidden">
                  <button
                    id="previousResultItem"
                    className="h-[112px] w-[112px] rounded-lg bg-[#F7F8FA]"
                    data-gtm="pest_diagnosis_poc_pestDiagnosisMain_previousResultItem_Click"
                    onClick={imageClick(index++)}
                  >
                    <BlurringImage
                      className="rounded-xl object-cover"
                      src={v.imgUrl}
                      layout="responsive"
                      priority={true}
                      width={"100%"}
                      height={"100%"}
                      sizes="(min-width: 576px) 176px, 30vw"
                      alt="prevImg"
                    />
                  </button>
                  <div className="flex flex-col">
                    <span className={`font-medium h-[22px] text-[15px]`}>
                      {v.predictedDiagnoses[0].diseaseName.length > 8
                        ? v.predictedDiagnoses[0].diseaseName.slice(0, 8) + "..."
                        : v.predictedDiagnoses[0].diseaseName}
                    </span>
                    <span className="font-normal text-[13px] text-[#65666B]">{`${date.getFullYear()}.${
                      date.getMonth() + 1
                    }.${date.getDate()}`}</span>
                  </div>
                  <div className="h-1"></div>
                </div>
              );
            } else if (index === 10) {
              return (
                <div key={index} className="mr-2 h-[112px] w-[112px] relative overflow-hidden rounded-lg bg-white">
                  <Link
                    href={{
                      pathname: ROUTE.RESULT_MY_LIST,
                    }}
                  >
                    <a id="resultMoreButton" className="absolute top-[42px] left-[28px]">
                      <div className="inline-flex text-[15px] font-medium text-[#8B8D94]">
                        <span>더보기</span>
                        <ChevronRightIcon className="w-4" />
                      </div>
                    </a>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </>
    );
  };

  /**
   * 지도 페이지 진입시 초기 상태로 설정을 바꾸기 위한 함수
   */
  const setMapCenterDefault = async () => {
    setPrevMapCenter(null);
    setCurrentZoomLevel(14);
    await setSnap(null);
  };

  /**
   * 구글 맵용 추가 옵션
   */
  const option = {
    disableDefaultUI: true,
    gestureHandling: "none",
    zoomControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  return (
    <>
      <Head>
        <title>자재 병해충처방 메인</title>
      </Head>
      <div className="relative h-[calc(100vh-56px)] min-h-[calc(792px-56px)] flex flex-col">
        {/*작물 확인 팝업*/}
        {cropPopupOpen && <CropPopup cropPopupClick={() => cropPopupClick()}></CropPopup>}

        {/*메인페이지 전용 가이드 영역*/}
        <div className="mx-4 mt-5">
          <div className="text-[#242424] text-2xl font-bold">
            병해충을 촬영 후 진단하고
            <br />내 주변 병해충도 확인하세요
          </div>
        </div>

        {/*병해충 지도 영역*/}
        {mapLoaded ? (
          <div className="mt-10 mx-4">
            <div className="flex flex-row items-center mb-2">
              <h2 className="text-[19px] text-[#242424] font-bold leading-6">{"병해충 지도"}</h2>
              <div className="right-4 absolute font-medium text-[15px] text-[#8B8D94]">
                <Link href={ROUTE.RESULT_ALL_LIST}>
                  <a className="flex flex-row" data-gtm="pest_diagnosis_main_allresult_click">
                    <span className="">{"모든 병해충 진단 결과"}</span>
                    <div className="self-center">
                      <ChevronRightIcon className="w-4" />
                    </div>
                  </a>
                </Link>
                {todayCount > 0 && (
                  <div
                    className={`w-[226px] py-2 px-3 bg-[#1F2024] absolute rounded-lg mt-2 left-[-85px] z-[1] transition-all duration-500 ease-in-out ${
                      toastVisible ? "opacity-100" : "opacity-0"
                    }`}
                  >
                    <div className="absolute z-[1] top-[-15px] left-[108px]">
                      <Image
                        src={"/icons/tooltip-arrow-top.svg"}
                        width={10}
                        height={6}
                        loading="eager"
                        alt="rectangle"
                      />
                    </div>
                    <span className="text-[13px] text-white font-normal">
                      {`오늘 ${todayCount}건의 진단이 새로 추가되었어요. 지금 확인해보세요!`}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="cursor-pointer relative">
              <Link
                href={{
                  pathname: "/cropmap",
                }}
              >
                <a
                  id="mainMap"
                  data-gtm="pest_diagnosis_main_map_click"
                  onClick={() => {
                    setMapCenterDefault();
                  }}
                >
                  <div className="bottom-8 absolute right-2 z-20 h-6">
                    <IconContainer>
                      <TouchIcon />
                    </IconContainer>
                  </div>
                  <MapLoader
                    type={"google"}
                    center={mapCenter}
                    myLocation={mapCenter}
                    myLocationVisible={true}
                    myLocationMarker={"/icons/spot.svg"}
                    zoom={14}
                    maxZoom={15}
                    locations={locations}
                    options={option}
                    style={{ height: "184px", borderRadius: "16px", zIndex: 0 }}
                    icon={"/icons/cluster.svg"}
                    markerStyle={{ text: "1", color: "white", fontWeight: "700", fontSize: "17px" }}
                    clusterIcon={"/icons/cluster.svg"}
                    clusterStyle={{ color: "white", fontWeight: "700", fontSize: "17px" }}
                  />
                </a>
              </Link>
            </div>
          </div>
        ) : (
          <div className="h-[216px]" />
        )}

        {/*이전 진단 내역 영역*/}
        {prevHistory.length > 0 ? (
          <div className="mt-8">
            <div className="mx-4 flex flex-wrap items-center">
              <h2 className="text-[19px] text-[#242424] font-bold leading-6">{"내 진단 내역"}</h2>
            </div>
            <div id="prevHistory" className="mt-2 w-full overflow-x-scroll">
              {prevDiagnosisImg()}
            </div>
          </div>
        ) : (
          <div id="prevHistory" />
        )}

        <div className="grow"></div>
        <div
          className="flex w-full max-w-xl cursor-pointer flex-row justify-center mt-10 font-medium text-[15px] text-[#8B8D94]"
          id="viewSupportedCropsButton"
          data-gtm="pest_diagnosis_poc_pestDiagnosisMain_viewSupportedCropsButton_Click"
          onClick={cropPopupClick}
        >
          <span className="">{"병해충 진단 가능한 작물 보기"}</span>
          <div className="self-center">
            <ChevronRightIcon className="w-5" />
          </div>
        </div>
        {/*버튼영역 회피를 위한 더미 영역*/}
        <div className="h-[106px]"></div>

        {/*촬영하기 버튼*/}
        <BottomFloatingButton
          id="takePhotoButton"
          dataGtm="pest_diagnosis_poc_pestDiagnosisMain_takePhotoButton_Click"
          onClick={photoBtnClick}
        >
          <CameraIconV2 />
          <span className="ml-1 font-bold text-[19px]">사진 촬영</span>
        </BottomFloatingButton>

        <div className={`fixed bottom-0 ${!capturePanelOpen ? "w-0" : "z-50 w-full max-w-xl"}`}>
          <div className={`relative`}>
            {capturePanelOpen && (
              <div className="fixed inset-0 overflow-y-auto bg-gray-500 opacity-80" onClick={photoBtnClick}></div>
            )}
            <div className={`relative h-[145px] ${!capturePanelOpen ? "overflow-y-hidden" : "z-50"}`}>
              <div
                id="capturePanel"
                className={`-bottom-[172px] h-[172px] w-full max-w-xl items-center rounded-t-xl bg-white px-5 py-[30px] ${
                  !capturePanelOpen
                    ? "translate-y-40"
                    : "z-50 -translate-y-[26px] transition-transform delay-100 duration-500"
                }`}
              >
                <div>
                  <label
                    htmlFor="takePhotoMenu"
                    id="takePhotoMenuLabel"
                    className="flex-cols flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/camera2.svg"}
                      width={24}
                      height={24}
                      alt="cameraIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">사진 촬영</div>
                    <input
                      type="file"
                      id="takePhotoMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_takePhotoMenu_Click"
                      capture
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                  <label
                    htmlFor="albumMenu"
                    id="albumMenuLabel"
                    className="flex-cols mt-2 flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/album.svg"}
                      width={24}
                      height={24}
                      alt="albumIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">앨범 선택</div>
                    <input
                      type="file"
                      id="albumMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_selectFileMenu_Click"
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EntrypointV3;
