import { ChangeEvent, useEffect, useCallback, useState, useMemo, useRef } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { PageProps } from "pages/_app";
import { throttle, cloneDeep } from "lodash-es";
import { map, pipe, toArray, toAsync, concurrent } from "@fxts/core";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useAtom } from "jotai";
import {
  captureAtom,
  mapCenterAtom,
  loadingAtom,
  ncpmsDetailListAtom,
  ncpmsDetailVisibleAtom,
  ncpmsInfoAtom,
  pesticidesDataListAtom,
  plantixAtom,
  prevHistoryAtom,
  defaultMapCenter,
  mapAddressAtom,
  mapZoomLevelAtom,
  bottomSheetSnapAtom,
  moveMapCenterAtom,
  resultPageSourceAtom,
  allResultListAtom,
  aroundResultListAtom,
  myResultListAtom,
  bottomSheetDataAtom,
  sortOrderAtom,
  defaultMapCenterV310,
  monthWarningListAtom,
  district1CoordinateAtom,
  aroundMyDiseaseActiveTabAtom,
  fertilizersDataListAtom,
  productTypeAtom,
  ncpmsDetailAtom,
  allResultListPage,
  myResultListPage,
  aroundResultListPage,
  monthlyDetailSourceAtom,
  defaultAddress,
} from "store/global";
import { COMMON, CROP_NAME, ENTRY_TYPE, ERROR } from "@constants/common";
import { nanoid } from "nanoid";
import {
  checkNull,
  resizeImageFileWithObjectURL,
  setDataLayer,
  FindCoordinate,
  Location,
  MapLoader,
  setSessionStorage,
  getSessionStorage,
  timeForToday,
  setLocalStorage,
  getLocalStorage,
  reactNativePostMessage,
  findAddress1Coordinate,
  checkVersion,
  QA_FLAG_DEV,
  QA_FLAG_STAGING,
} from "@vhows/util";
import {
  checkTodayCount,
  DiseaseAnalysis,
  diseaseAnalysis,
  diseaseListData,
  diseaseListCoordinate,
  getDiseaseAnalysis,
  getERPAddress,
  getPresignedUrl,
  INDICATORS,
  logging,
  ncpmsDetail,
  ncpmsInfo,
  pesticideList,
  uploadImageFile,
  diseaseListAll,
  monthWarningDiseaseListAll,
  DiseaseAnalysisMonthly,
  resultDetail,
  Diagnoses,
  getFertilizers,
} from "service/vhows";
import { BottomFloatingButton, BlurringImage, Tab } from "ui";
import CropPopup from "./crop-popup";
import { ROUTE } from "@constants/route";
import TouchIcon from "components/icons/touch-icon";
import CameraIconV2 from "components/icons/camera-icon-v2";
import { IconContainer } from "components/icons/icon-container";
import EntryList from "components/entry-list";
import EntryListV2 from "components/entry-list/entry-list-v2";
import PesicideCompanyV1 from "../../components/pesticide-company";
import PesicideCompanyV2 from "../../components/pesticide-company/pesticide-compnay-v2";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, FreeMode } from "swiper";
import { getFarmmorningAccessToken, getFarmmorningUserInfo, updateFarmmorningUserAddress } from "@vhows/rest";
import { PRODUCT_TYPE } from "@constants/product";

function EntrypointV320(_: PageProps) {
  const [, setSource] = useAtom(resultPageSourceAtom);
  const [, setLoading] = useAtom(loadingAtom);
  const [, setCapture] = useAtom(captureAtom);
  const [, setResponsePlantix] = useAtom(plantixAtom);
  const [, setPesticidesDataList] = useAtom(pesticidesDataListAtom);
  const [, setFertilizersDataList] = useAtom(fertilizersDataListAtom);
  const [, setProductType] = useAtom(productTypeAtom);
  const [, setDetailVisible] = useAtom(ncpmsDetailVisibleAtom);
  const [, setNcpmsDetailInfo] = useAtom(ncpmsDetailAtom);
  const [prevHistory, setPrevHistory] = useAtom(prevHistoryAtom);
  const [mapCenter, setMapCenter] = useAtom(mapCenterAtom);
  const [mapAddress, setMapAddress] = useAtom(mapAddressAtom);
  const [, setPrevMapCenter] = useAtom(moveMapCenterAtom);
  const [, setCurrentZoomLevel] = useAtom(mapZoomLevelAtom); // 현재 지도 줌 레벨
  const [, setSnap] = useAtom(bottomSheetSnapAtom); // 바텀 시트 확장 높이
  const [, setDataListMy] = useAtom(myResultListAtom);
  const [, setDataListAll] = useAtom(allResultListAtom);
  const [, setDataListBottom] = useAtom(bottomSheetDataAtom); // 바텀 시트 데이터 배열
  const [, setSortOrder] = useAtom(sortOrderAtom);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [capturePanelOpen, setCapturePanelOpen] = useState<boolean>(false);
  const [cropPopupOpen, setCropPopupOpen] = useState<boolean>(false);
  const [todayCount, setTodayCount] = useState<number>(0);
  const [toastVisible, setToastVisible] = useState<boolean>(true);
  const [diseaseList, setDiseaseList] = useAtom(aroundResultListAtom);
  const [allDiseaseList, setAllDiseaseList] = useAtom(allResultListAtom);
  const [, setMonthlyDetailSource] = useAtom(monthlyDetailSourceAtom);
  const [monthWarningList, setMonthWarningList] = useState<DiseaseAnalysisMonthly[]>([]);
  const defaultCoordinate = { latitude: defaultMapCenter.lat, longitude: defaultMapCenter.lng };
  const [activeTab, setActiveTab] = useAtom(aroundMyDiseaseActiveTabAtom);
  // 토큰 재요청 여부
  const isGetToken = useRef<boolean>(false);
  const [, setAllListPageNumber] = useAtom(allResultListPage);
  const [, setMyListPageNumber] = useAtom(myResultListPage);
  const [, setAroundPageNumber] = useAtom(aroundResultListPage);
  const router = useRouter();
  // 팜모닝및 ERP에서 넘어왔을 경우, 유저 아이디 정보
  let userId = router.query.uuid as string;
  let type = router.query.type as string;
  const district_1 = router.query.address1 as string;
  const district_2 = router.query.address2 as string;
  const district_3 = router.query.address3 as string;

  useEffect(() => {
    const prevScrollPosition = getSessionStorage("entryScrollPosition");
    if (prevScrollPosition) {
      window.scrollTo({ left: 0, top: +prevScrollPosition });
      sessionStorage.removeItem("entryScrollPosition");
    }
  }, []);

  useEffect(() => {
    init();
    getTodayCount();

    reactNativePostMessage("GET_TOKEN");

    document.addEventListener("GET_TOKEN_RESULT", getRefreshToken);

    return () => document.removeEventListener("GET_TOKEN_RESULT", getRefreshToken);
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    getPrevHistory();
  }, [router.isReady]);

  useEffect(() => {
    if (mapCenter.lat && mapCenter.lng)
      Promise.all([getAnalysisByCoords(), getAllAnalysis(), getMonthWarningAnalysis(), getLocations()]);
  }, [mapCenter.lat, mapCenter.lng]);

  useEffect(() => {
    if (mapLoaded && !mapAddress?.district_1) {
      router.replace(ROUTE.ADDRESS);
    }
  }, [mapLoaded, mapAddress?.district_1]);

  /**
   * RN refresh Token receive
   */
  const getRefreshToken = async (event: any) => {
    console.log(event);
    try {
      const result = await getFarmmorningAccessToken(event.detail.result);
      setLocalStorage(COMMON.FARMMORNING_ACCESS_TOKEN, result.access_token);
      setLocalStorage(COMMON.FARMMORNING_REFRESH_TOKEN, event.detail.result);
    } catch (e: any) {
      if (e?.type === "expired_token" && !isGetToken.current) {
        isGetToken.current = true;
        reactNativePostMessage("GET_TOKEN");
      }
    }
  };

  /**
   * 초기화
   */
  const init = () => {
    setLoading(false);
    setCapture("");
    setResponsePlantix(null);
    setPesticidesDataList([]);
    setFertilizersDataList([]);
    setSessionStorage("bottom_sheet_scroll_" + ROUTE.CROPMAP, 0);
    setSessionStorage(`scroll-y-${ENTRY_TYPE.DETAIL}`, 0);
    setDataListMy([]);
    setDataListAll([]);
    setDataListBottom([]);
    setAllListPageNumber(1);
    setMyListPageNumber(0);
  };

  /**
   * 오늘 신규 진단 건수 조회
   */
  const getTodayCount = async () => {
    const count = await checkTodayCount();
    setTodayCount(count);
    setTimeout(() => {
      setToastVisible(false);
    }, 5000);
  };

  /**
   * 이전 진단 내역 조회 로직
   */
  const getPrevHistory = useCallback(
    throttle(
      async () => {
        if (!type && !!getSessionStorage("entryType")) {
          type = getSessionStorage("entryType");
        }
        // 외부 url 에서 접근시, router.query 정보 없을때
        if (!userId && router.asPath.indexOf("uuid") > -1) {
          const param = new URLSearchParams(window.location.search);
          userId = param.get("uuid") as string;
        }
        const id = userId || getLocalStorage(COMMON.USER_ID); // 팜모닝 유저 아이디
        const tid = getLocalStorage(COMMON.TEMPORARY_ID); // 저장된 임시 아이디
        let result;
        try {
          if (id) {
            setLocalStorage(COMMON.USER_ID, id);
            if (tid) {
              result = await getDiseaseAnalysis(id, 11);
            } else {
              result = await getDiseaseAnalysis(id, 11);
            }
            await analysis(id);
          } else {
            if (tid) {
              // 비회원일 경우, 로컬스토리지의 임시정보로 이전내역 불러오기
              result = await getDiseaseAnalysis(tid, 11);
              await analysis(tid);
            } else {
              // 비회원일 경우, 임시아이디 발급 후 요청
              const tid = nanoid();
              setLocalStorage(COMMON.TEMPORARY_ID, tid);
              result = await getDiseaseAnalysis(tid, 11);
              await analysis(tid);
            }
          }
          if (result && result.length > 0) {
            setPrevHistory(result);
          }
        } catch (e: any) {
          console.log(e);
        } finally {
          await entryCheck();
        }
      },
      500,
      { trailing: false },
    ),
    [userId],
  );

  // 주소 저장 로직
  const setAddress = async (data: { address1: string; address2: string; address3: string }) => {
    const center = await FindCoordinate(data.address1, data.address2, data.address3);
    setMapCenter({ lat: center.latitude, lng: center.longitude });
    setMapAddress({
      district_1: data.address1,
      district_2: data.address2,
      district_3: data.address3,
    });
    setSessionStorage("address", {
      district_1: data.address1,
      district_2: data.address2,
      district_3: data.address3,
      latitude: center.latitude,
      longitude: center.longitude,
    });
    await router.replace(
      {
        pathname: "/",
      },
      undefined,
      { shallow: true },
    );
  };

  const entryCheck = async () => {
    if (!!type && (type === "ws" || type === "as")) {
      // 우성,아산 ERP 진입
      setSessionStorage("entryType", type);
      const userKey = userId ? userId : getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
      try {
        const erpAddress = await getERPAddress(userKey as string, type);
        setMapCenter({ lat: erpAddress.latitude, lng: erpAddress.longitude });
        setMapAddress({
          district_1: erpAddress.district_1,
          district_2: erpAddress.district_2,
          district_3: erpAddress.district_3,
        });
        setSessionStorage("address", {
          district_1: erpAddress.district_1,
          district_2: erpAddress.district_2,
          district_3: erpAddress.district_3,
          latitude: erpAddress.latitude,
          longitude: erpAddress.longitude,
        });
        await router.replace(
          {
            pathname: "/",
            // query: {
            //   address1: district_1,
            //   address2: district_2,
            //   address3: district_3,
            // },
          },
          undefined,
          { shallow: true },
        );
      } catch (e) {
        console.log("ERPAddress API error", e);
      }
    } else {
      const accessToken = getLocalStorage(COMMON.FARMMORNING_ACCESS_TOKEN);
      const refreshToken = getLocalStorage(COMMON.FARMMORNING_REFRESH_TOKEN);

      if (accessToken && refreshToken) {
        // 토큰이 모두 존재하는 경우
        try {
          const { data, errors } = await getFarmmorningUserInfo(accessToken);
          if (data && data.me.communityUser.userAddress) {
            // 주소정보가 있는 경우
            await setAddress(data.me.communityUser.userAddress);
          } else if (errors && errors?.find((v: any) => v.message === "Unauthorized")) {
            // 토큰이 비정상적인 경우
            const result = await getFarmmorningAccessToken(refreshToken);
            if (result.access_token) {
              setLocalStorage(COMMON.FARMMORNING_ACCESS_TOKEN, result.access_token);
              const { data } = await getFarmmorningUserInfo(result.access_token);
              data && data.me.communityUser.userAddress && (await setAddress(data.me.communityUser.userAddress));
            }
          }
        } catch (e: any) {
          console.log(e);
          const address = getSessionStorage("address");
          if (!!district_1 && !!district_2 && !!district_3) {
            // url 에 주소정보가 전달된경우
            await setAddress({ address1: district_1, address2: district_2, address3: district_3 });
          } else if (address) {
            // 세션스토리지에 주소가 저장되어있는 경우
            setMapCenter({ lat: address.latitude, lng: address.longitude });
            setMapAddress({
              district_1: address.district_1,
              district_2: address.district_2,
              district_3: address.district_3,
            });
          }
        }
      } else if (!!district_1 && !!district_2 && !!district_3) {
        // 팜모닝 진입 (주소 있는 회원)
        const [center, initMapCenter] = await Promise.all([
          await FindCoordinate(district_1, district_2, district_3, defaultCoordinate),
          await findAddress1Coordinate(district_1, defaultCoordinate),
        ]);
        setMapCenter({ lat: center.latitude, lng: center.longitude });
        setMapAddress({ district_1: district_1, district_2: district_2, district_3: district_3 });
        setSessionStorage("address", {
          district_1: district_1,
          district_2: district_2,
          district_3: district_3,
          latitude: center.latitude,
          longitude: center.longitude,
        });
        await router.replace(
          {
            pathname: "/",
            // query: {
            //   address1: district_1,
            //   address2: district_2,
            //   address3: district_3,
            // },
          },
          undefined,
          { shallow: true },
        );
      } else {
        const address = getSessionStorage("address");
        if (address) {
          setMapCenter({ lat: address.latitude, lng: address.longitude });
          setMapAddress({
            district_1: address.district_1,
            district_2: address.district_2,
            district_3: address.district_3,
          });
        }
      }
    }
    if (!getSessionStorage("address")) {
      setSortOrder("LATEST");
    }
    setMapLoaded(true);
  };

  const getLocations = async () => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    if (userKey) {
      const data = await diseaseListCoordinate(mapCenter.lat, mapCenter.lng, userKey, 100, 6, null);
      setLocations(data);
    }
  };

  /**
   * 로깅 관련 함수
   * @param id
   * 유저 아이디
   */
  const analysis = (id: string) => {
    // test();
    logging(null, INDICATORS.ENTER, null, id);
    setDataLayer({ event: "pest_diagnosis_poc_pestDiagnosisMain_diagnosisMainScreen_Enter", user_uuid: id });
    setLocalStorage("profile-screen-enter", { source: "from_nearPrescription_main" });
  };

  /**
   * 이미지 리사이즈
   * @param file
   * 이미지 파일
   */
  const resizeImage = async (file: File): Promise<File> => {
    const [resizedImageBase64, resizedImageFile] = await resizeImageFileWithObjectURL(file);
    setCapture(resizedImageBase64);
    return resizedImageFile;
  };

  /**
   * 파일 업로드 (사진 촬영)
   * @param e
   * 파일
   */
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setCapturePanelOpen(false);
    const files = e.target.files;
    if (files && files[0]) {
      init();
      setLoading(true);
      try {
        const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
        if (userKey) {
          logging(null, INDICATORS.DIAGNOSIS, null, userKey);
          const file = await resizeImage(files[0]);
          const s3 = await getPresignedUrl(files[0].name);
          await uploadImageFile(s3.presignedUrl, file);
          const userType = getSessionStorage("entryType") || type;
          const response = await diseaseAnalysis(
            s3.cdnUrl,
            file,
            false,
            userKey,
            getSessionStorage("address"),
            userType,
          );
          setResponsePlantix(response);
          // 정상 응답인 경우에도 에러 띄워야 하는 케이스에 대해서 확인
          logging(null, INDICATORS.DIAGNOSIS_SUCCESS, null, userKey);
          if (response.apiStatus === "999") {
            await router.push("/error");
          } else {
            await Promise.all([loadNcpms(response), loadProductsList(response)]);
            setSource("from_takePhoto");
            await router.push(`/result/${response.traceId}`);
          }
        } else {
          throw new Error("사용자가 존재하지 않습니다.");
        }
      } catch (e: any) {
        if (e.response?.data && "code" in e.response.data) setResponsePlantix(e.response.data);
        else setResponsePlantix(null);
        await router.push("/error");
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * 농진청 API 호출 관련 함수
   * @param plantixResponse
   * plantix 응답값
   */
  const loadNcpms = async (plantixResponse: DiseaseAnalysis) => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    const values: ResultDetail[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => resultDetail(plantixResponse.traceId, v.id, userKey)),
      concurrent(3),
      toArray,
    );

    const buttonVisibleValues: boolean[] = values.map((v: ResultDetail, index: number) => {
      return !!v;
    });

    setDetailVisible(buttonVisibleValues);
  };

  /**
   * 추천 제품 리스트 호출 관련 함수
   */
  const loadProductsList = async (plantixResponse: DiseaseAnalysis) => {
    // 추천 농약 호출
    const pesticidesArr: PesticidesDataList = [];
    const values: PesticidesList[] = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => pesticideList(11, 1, v.diseaseName, v[CROP_NAME][0])),
      concurrent(3),
      toArray,
    );
    pipe(
      values,
      map(v => pesticidesArr.push(v.data)),
      toArray,
    );
    setPesticidesDataList(pesticidesArr);

    // 추천 비료 호출
    const fertilizersArr: FertilizersDataList = await pipe(
      plantixResponse.predictedDiagnoses,
      toAsync,
      map(v => getFertilizers({ size: 5, page: 1, pestName: v.diseaseName, cropName: v[CROP_NAME][0] })),
      concurrent(3),
      toArray,
    );
    setFertilizersDataList(fertilizersArr);

    // 농약, 비료 (제품) 리스트 판별 및 이에 따른 타입 유형 저장
    const typeArr: number[] = [];
    if (plantixResponse && "predictedDiagnoses" in plantixResponse) {
      plantixResponse.predictedDiagnoses.map((v: Diagnoses, index: number) => {
        if (!!fertilizersArr[index] && fertilizersArr[index].length > 0) {
          if (!!pesticidesArr[index] && pesticidesArr[index].length > 0) {
            typeArr.push(PRODUCT_TYPE.ALL);
          } else {
            typeArr.push(PRODUCT_TYPE.FERTILIZER);
          }
        } else if (!!pesticidesArr[index] && pesticidesArr[index].length > 0) {
          typeArr.push(PRODUCT_TYPE.PESTICIDE);
        } else {
          typeArr.push(PRODUCT_TYPE.NULL);
        }
      });
      setProductType(typeArr);
    }
  };

  /**
   * 작물 종류 팝업 on, off
   */
  const cropPopupClick = () => {
    setCropPopupOpen(!cropPopupOpen);
  };

  /**
   * 사진 촬영 하단 판넬 on, off
   */
  const photoBtnClick = () => {
    setCapturePanelOpen(!capturePanelOpen);
  };

  /**
   * 월별 추천 제품 리스트 호출 관련 함수
   */
  const loadMonthlyProductsList = async (ncpmsResponse: NcpmsDetailResponse) => {
    // 추천 농약 호출
    const pesticidesArr: PesticidesDataList = [];
    const pesticideListData = await pesticideList(11, 1, ncpmsResponse.title, ncpmsResponse.cropName);

    pipe(
      [pesticideListData],
      map(v => pesticidesArr.push(v.data)),
      toArray,
    );
    setPesticidesDataList(pesticidesArr);

    // 추천 비료 호출
    const fertilizersListData = await getFertilizers({
      size: 5,
      page: 1,
      pestName: ncpmsResponse.title,
      cropName: ncpmsResponse.cropName,
    });
    const fertilizersArr: FertilizersDataList = [fertilizersListData];
    setFertilizersDataList(fertilizersArr);

    // 농약, 비료 (제품) 리스트 판별 및 이에 따른 타입 유형 저장
    const typeArr: number[] = [];

    if (!!fertilizersArr[0] && fertilizersArr[0].length > 0) {
      if (!!pesticidesArr[0] && pesticidesArr[0].length > 0) {
        typeArr.push(PRODUCT_TYPE.ALL);
      } else {
        typeArr.push(PRODUCT_TYPE.FERTILIZER);
      }
    } else if (!!pesticidesArr[0] && pesticidesArr[0].length > 0) {
      typeArr.push(PRODUCT_TYPE.PESTICIDE);
    } else {
      typeArr.push(PRODUCT_TYPE.NULL);
    }

    setProductType(typeArr);

    return typeArr;
  };

  /**
   * 월별 주의해야할 병해충 이미지 클릭 함수
   * @param index
   * 이미지 인덱스
   * @param data
   * 인덱스를 통해 찾을 리스트
   */
  const monthlyImageClick = (index: number, data: any) => async () => {
    try {
      const detailData = await ncpmsDetail(data.ncpmsId);
      await loadMonthlyProductsList(detailData);
      setNcpmsDetailInfo(detailData);
      setMonthlyDetailSource("from_monthlyDisease_main");
      router.push(`/detail/${data.ncpmsId}`);
    } catch (e: any) {
      console.log("error: ", e);
      setLoading(false);
      router.push("/error");
    }
  };

  /**
   * 이전 진단 내역 이미지 클릭 함수
   * @param index
   * 이미지 인덱스
   * @param data
   * 인덱스를 통해 찾을 리스트
   * @param type
   * 진입 루트
   */
  const imageClick = (index: number, data: any, type?: string) => async () => {
    setLoading(true);
    try {
      const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
      if (userKey) logging(null, INDICATORS.PREV_IMAGE_CLICK, null, userKey);
      setCapture(data.imgUrl);
      setResponsePlantix(data);
      setSource(
        type === "main_all" ? "from_allResult_main" : type === "around" ? "from_nearResult_main" : "from_myResult_main",
      );
      await Promise.all([loadNcpms(data), loadProductsList(data)]);
      await router.push(`/result/${data.traceId}`);
    } catch (e: any) {
      if (e?.type === ERROR.NO_DETAIL_INFO) {
        console.log("error: ", e);
        setLoading(false);
        router.push({ pathname: "/error", query: { type: e.type } });
      } else {
        console.log("error: ", e);
        setLoading(false);
        await router.push("/error");
      }
    }
  };

  /**
   * swiper 스크롤 유지를 위해 swiper index 저장
   */
  const handleSlideChange = (swiper: any, type: "my" | "around") => {
    if (type === "my") {
      setSessionStorage("mySwiper", swiper.activeIndex);
    } else {
      setSessionStorage("aroundSwiper", swiper.activeIndex);
    }
  };

  /**
   * swiper init after 세션의 active index 제거
   */

  const afterSwiperInit = (swiper: any) => {
    sessionStorage.removeItem("aroundSwiper");
    sessionStorage.removeItem("mySwiper");
  };

  /**
   * 이전 진단 내역 영역 구성 로직
   */
  const makeDiagnosisImgs = (data: DiseaseAnalysis[], type: "my" | "around") => {
    let index = 0;
    const initSeq = type === "my" ? getSessionStorage("mySwiper") : getSessionStorage("aroundSwiper");
    return (
      <>
        <Swiper
          id="diseaseList_swiper"
          key={type}
          modules={[Mousewheel, FreeMode]}
          slidesPerView={"auto"}
          mousewheel={{
            forceToAxis: true,
            releaseOnEdges: true,
          }}
          initialSlide={initSeq || 0}
          freeMode={{
            enabled: true,
            sticky: true,
          }}
          onAfterInit={afterSwiperInit}
          onSlideChange={swiper => handleSlideChange(swiper, type)}
        >
          {data.map((v: DiseaseAnalysis, index: number) => {
            const date = new Date(v.createDate);
            if (index < 10) {
              return (
                <SwiperSlide key={v.imgUrl} className="first:ml-4 mr-2 overflow-hidden !w-[112px]">
                  <button
                    id="previousResultItem"
                    className="h-[112px] w-[112px] rounded-lg bg-[#F7F8FA]"
                    data-gtm={
                      type === "around"
                        ? "pest_diagnosis_main_neardiagnosis_list_click"
                        : "pest_diagnosis_main_mydiagnosis_list_click"
                    }
                    onClick={imageClick(index++, v, type)}
                  >
                    <BlurringImage
                      className="rounded-xl object-cover"
                      src={v.imgUrl}
                      layout="responsive"
                      priority={true}
                      width={"100%"}
                      height={"100%"}
                      sizes="(min-width: 576px) 176px, 30vw"
                      alt="prevImg"
                    />
                  </button>
                  <div className="flex flex-col">
                    <span className={`font-medium text-[15px]`}>{findDiagnoses(v)}</span>
                    <span className="font-normal text-[13px] text-[#65666B]">{`${date.getFullYear()}.${
                      date.getMonth() + 1
                    }.${date.getDate()}`}</span>
                  </div>
                  <div className="h-1"></div>
                </SwiperSlide>
              );
            } else if (index === 10) {
              return (
                <SwiperSlide
                  key={index}
                  className="mr-2 !h-[112px] !w-[112px] relative overflow-hidden rounded-lg bg-white"
                >
                  <Link
                    href={{
                      pathname: type === "my" ? ROUTE.RESULT_MY_LIST : ROUTE.CROPMAP,
                    }}
                  >
                    <a id="resultMoreButton" className="absolute top-[42px] left-[28px]">
                      <div className="inline-flex text-[15px] font-medium text-[#8B8D94]">
                        <span>더보기</span>
                        <ChevronRightIcon className="w-4" />
                      </div>
                    </a>
                  </Link>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </>
    );
  };

  /**
   * 내 진단 결과가 없는 경우
   * */

  const noDiseaseList = () => {
    return (
      <div
        id="prevHistory"
        className="justify-center items-center h-[165px] pt-4 text-center font-normal flex flex-col"
      >
        <div>
          <div className="text-[#1F2024] mb-2 text-[17px]">진단한 작물이 없어요</div>
          <div className="text-[#8B8D94] text-[15px]">사진 촬영으로 병해충을 진단해 보세요.</div>
        </div>
        {/*촬영하기 버튼*/}
        <button
          className="px-3 py-2 rounded-lg mt-[16px] text-[#0BB25F] font-medium border border-[#0BB25F] flex items-center justify-center"
          id="takePhotoButton"
          data-gtm="pest_diagnosis_poc_pestDiagnosisMain_takePhotoButton_Click"
          onClick={photoBtnClick}
        >
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.555 7.195C22.105 6.745 21.51 6.5 20.875 6.5H18.11C18.005 6.5 17.865 6.425 17.755 6.315L16.545 4.405C16.525 4.37 16.495 4.335 16.47 4.305C16.025 3.785 15.425 3.5 14.78 3.5H10.47C9.825 3.5 9.22 3.785 8.78 4.305C8.755 4.335 8.73 4.37 8.705 4.405L7.495 6.315C7.42 6.395 7.3 6.5 7.19 6.5H4.375C3.74 6.5 3.145 6.745 2.695 7.195C2.245 7.645 2 8.24 2 8.875V17.875C2 18.51 2.245 19.105 2.695 19.555C3.145 20.005 3.74 20.25 4.375 20.25H20.875C21.51 20.25 22.105 20.005 22.555 19.555C23.005 19.105 23.25 18.51 23.25 17.875V8.875C23.25 8.24 23.005 7.645 22.555 7.195ZM12.625 16.965C10.14 16.965 8.125 15.01 8.125 12.6C8.125 10.19 10.14 8.235 12.625 8.235C15.11 8.235 17.125 10.19 17.125 12.6C17.125 15.01 15.11 16.965 12.625 16.965Z"
              fill="#0BB25F"
            />
            <path
              d="M12.625 15.625C14.2819 15.625 15.625 14.2819 15.625 12.625C15.625 10.9681 14.2819 9.625 12.625 9.625C10.9681 9.625 9.625 10.9681 9.625 12.625C9.625 14.2819 10.9681 15.625 12.625 15.625Z"
              fill="#0BB25F"
            />
          </svg>
          <span className="ml-1 font-medium text-[15px]">내 작물 진단하기</span>
        </button>
      </div>
    );
  };

  /**
   * 지도 페이지 진입시 초기 상태로 설정을 바꾸기 위한 함수
   */
  const setMapCenterDefault = async () => {
    setPrevMapCenter(null);
    setCurrentZoomLevel(10);
    await setSnap(null);
  };

  /**
   * 구글 맵용 추가 옵션
   */
  const option = {
    disableDefaultUI: true,
    gestureHandling: "none",
    zoomControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  /**
   * 내 주변 병해충 진단 조회
   */

  const getAnalysisByCoords = async () => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    if (userKey) {
      const result = await diseaseListData(mapCenter.lat, mapCenter.lng, userKey, 11, 1, 6, null);
      setDiseaseList(result);
      setAroundPageNumber(1);
    }
  };

  /**
   * 전체 병해충 진단 내역 조회
   */
  const getAllAnalysis = async () => {
    const userKey = getLocalStorage(COMMON.USER_ID) || getLocalStorage(COMMON.TEMPORARY_ID);
    if (userKey) {
      const result = await diseaseListAll(userKey, 11, 1, "LATEST", null, 0, 0);
      setAllDiseaseList(result);
    }
  };
  function toStringByFormatting(source: Date, delimiter = "-") {
    // 2023-11-01
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return [year, month, "01"].join(delimiter);
  }

  function leftPad(value: number) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  /**
   * 월별 주의할야할 병해충 진단 내역 조회
   */
  const getMonthWarningAnalysis = async () => {
    const userKey = localStorage.getItem(COMMON.USER_ID) || localStorage.getItem(COMMON.TEMPORARY_ID);
    const cautionDate = toStringByFormatting(new Date());
    if (userKey) {
      const result = await monthWarningDiseaseListAll(userKey, 3, 1, cautionDate, null);
      setMonthWarningList(result);
    }
  };

  const tabArray = [
    { text: "내 주변 진단", id: "around-tab", dataGtm: "pest_diagnosis_main_neardiagnosis_click" },
    { text: "내 진단", id: "prev-history-tab", dataGtm: "pest_diagnosis_main_mydiagnosis_click" },
  ];

  const tabClick = (index: number) => {
    switch (index) {
      case 1:
        setActiveTab("my");
        break;
      case 0:
        setActiveTab("around");
        break;
      default:
        setActiveTab("around");
        break;
    }
  };

  const findDiagnoses = (v: DiseaseAnalysis) => {
    const findDiagnose = v.predictedDiagnoses.find(v => !!v.diseaseName);
    if (findDiagnose) {
      // return findDiagnose.diseaseName.length > 8
      //   ? findDiagnose.diseaseName.slice(0, 8) + "..."
      //   : findDiagnose.diseaseName;
      return findDiagnose.diseaseName;
    } else return "진단 어려움";
  };

  const clusterClickEvent = () => {
    router.push(ROUTE.CROPMAP);
  };

  return (
    <>
      <Head>
        <title>자재 병해충처방 메인</title>
      </Head>
      <div className="relative">
        {/*작물 확인 팝업*/}
        {cropPopupOpen && <CropPopup cropPopupClick={() => cropPopupClick()}></CropPopup>}

        {/*실시간 전국 병해충 소식*/}
        <div>
          <div className="mx-4 flex flex-wrap items-center">
            <h2 className="text-[19px] text-[#242424] font-bold leading-6 inline-flex items-center">
              {"실시간 전국 병해충 소식"}
              <span className="ml-1 w-[20px] h-[20px] relative">
                <Image src="/icons/new-badge.svg" layout="fill" alt="new-badge" />
              </span>
            </h2>
          </div>
          <div className="mx-4">
            {allDiseaseList.length ? (
              allDiseaseList
                .slice(0, 3)
                .map((v, i) =>
                  checkVersion("3.2.4", QA_FLAG_STAGING) ? (
                    <EntryListV2
                      key={v.id}
                      image={v.imgUrl}
                      title={findDiagnoses(v)}
                      content={
                        v.district
                          ? `${v.district?.district_1} ${v.district?.district_2} ${v.district?.district_3}`
                          : ""
                      }
                      time={timeForToday(v.createDate)}
                      onClick={imageClick(i, v, "main_all")}
                      dataGtm={"pest_diagnosis_main_allresult_list_click"}
                    />
                  ) : (
                    <EntryList
                      key={v.id}
                      image={v.imgUrl}
                      title={findDiagnoses(v)}
                      content={
                        v.district
                          ? `${v.district?.district_1} ${v.district?.district_2} ${v.district?.district_3}`
                          : ""
                      }
                      time={timeForToday(v.createDate)}
                      onClick={imageClick(i, v, "main_all")}
                      dataGtm={"pest_diagnosis_main_allresult_list_click"}
                    />
                  ),
                )
            ) : (
              <div className="h-[422px]"></div>
            )}
          </div>
          <div className="mt-4 mx-4">
            <button
              className="h-10 rounded-[10px] border border-[#DCDFE3] w-full"
              id="allresult_moreviewbutton"
              data-gtm="pest_diagnosis_main_allresult_moreviewbutton_click"
              onClick={() => router.push(ROUTE.RESULT_ALL_LIST)}
            >
              <span className="leading-normal text-[15px] text-[#1F2024] font-medium">더 보기</span>
            </button>
          </div>
        </div>

        <div className="mt-8 h-2 bg-[#F0F2F5]" />

        {/*병해충 지도 영역*/}
        {mapLoaded ? (
          <div className="h-[216px] mt-8 mx-4">
            <div className="flex flex-row items-center mb-2">
              <h2 className="text-[19px] text-[#242424] font-bold leading-6 inline-flex items-center">
                {"내 주변 병해충 지도"}
                <span className="ml-1 w-[20px] h-[20px] relative">
                  <Image src="/icons/new-badge.svg" layout="fill" alt="new-badge" />
                </span>
              </h2>
            </div>

            <div className="cursor-pointer relative">
              <Link
                href={{
                  pathname: "/cropmap",
                }}
              >
                <a
                  id="mainMap"
                  data-gtm="pest_main_cropMap_click"
                  onClick={() => {
                    setMapCenterDefault();
                  }}
                >
                  <div className="bottom-8 absolute right-2 z-20 h-6">
                    <IconContainer>
                      <TouchIcon />
                    </IconContainer>
                  </div>
                  <MapLoader
                    type={"google"}
                    center={mapCenter}
                    myLocation={mapCenter}
                    myLocationVisible={true}
                    myLocationMarker={"/icons/spot.svg"}
                    zoom={10}
                    maxZoom={15}
                    locations={locations}
                    options={option}
                    style={{ height: "184px", borderRadius: "16px", zIndex: 0 }}
                    icon={"/icons/cluster.svg"}
                    markerStyle={{ text: "1", color: "white", fontWeight: "700", fontSize: "17px" }}
                    clusterIcon={"/icons/cluster.svg"}
                    clusterStyle={{ color: "white", fontWeight: "700", fontSize: "17px" }}
                    clusterClickEvent={clusterClickEvent}
                  />
                </a>
              </Link>
            </div>
          </div>
        ) : (
          <div className="h-[248px]" />
        )}

        {/* 내 주변/자신 진단내역 탭 */}
        <div className="relative mt-[16px]">
          <Tab
            tabArr={tabArray}
            defaultTab={activeTab === "around" ? 0 : 1}
            currentTab={activeTab === "around" ? 0 : 1}
            callbacks={tabClick}
            rootStyles="h-[48px]"
            containerStyle="px-[16px]"
            tabStylesFocus={`grow w-0`}
            tabStylesUnfocus={`grow w-0`}
          />
          {activeTab === "around" ? (
            diseaseList.length > 0 ? (
              <div id="diseaseList" className="no-scrollbar mt-4 w-full overflow-x-auto">
                {makeDiagnosisImgs(diseaseList, "around")}
              </div>
            ) : (
              <div id="diseaseList" className="h-[165px]"></div>
            )
          ) : prevHistory.length > 0 ? (
            <div id="prevHistory" className="no-scrollbar mt-4 w-full overflow-x-auto">
              {makeDiagnosisImgs(prevHistory, "my")}
            </div>
          ) : (
            noDiseaseList()
          )}
        </div>

        <div className="mt-8 h-2 bg-[#F0F2F5]" />

        {/*주변 농약사*/}
        {checkVersion("3.2.4", QA_FLAG_STAGING) ? <PesicideCompanyV2 /> : <PesicideCompanyV1 />}

        <div className="mt-8 h-2 bg-[#F0F2F5]" />

        {/*월별 주의해야할 병해충*/}
        <div>
          <div className="mt-8 mx-4 flex flex-wrap items-center">
            <h2 className="text-[19px] text-[#242424] font-bold leading-6 inline-flex items-center">
              {`이번 달 주의해야할 병해충`}
            </h2>
          </div>
          <div className="mx-4">
            {monthWarningList.length ? (
              monthWarningList.map((v, i) =>
                checkVersion("3.2.4", QA_FLAG_STAGING) ? (
                  <EntryListV2
                    key={v.imgUrl + i}
                    image={v.imgUrl}
                    title={v.diseaseName.length > 8 ? v.diseaseName.slice(0, 8) + "..." : v.diseaseName}
                    crop={v.cropKorName}
                    content={v.description.split("<br/>").join(" ")}
                    onClick={monthlyImageClick(i, v)}
                    dataGtm="pest_diagnosis_main_monthlycare_list_click"
                  />
                ) : (
                  <EntryList
                    key={v.imgUrl + i}
                    image={v.imgUrl}
                    title={v.diseaseName.length > 8 ? v.diseaseName.slice(0, 8) + "..." : v.diseaseName}
                    crop={v.cropKorName}
                    content={v.description.split("<br/>").join(" ")}
                    onClick={monthlyImageClick(i, v)}
                    dataGtm="pest_diagnosis_main_monthlycare_list_click"
                  />
                ),
              )
            ) : (
              <div className="h-[422px]"></div>
            )}
          </div>
          <div className="mt-4 mx-4">
            <button
              className="rounded-[10px] border border-[#DCDFE3] w-full h-10"
              id="monthlycare_moreviewbutton"
              data-gtm="pest_diagnosis_main_monthlycare_moreviewbutton_click"
              onClick={() => router.push(ROUTE.RESULT_MONTH_WARNING)}
            >
              <span className="leading-normal text-[15px] text-[#1F2024] font-medium">더 보기</span>
            </button>
          </div>
        </div>
        <div className="mt-8 h-2 bg-[#F0F2F5]" />

        {/*버튼영역 회피를 위한 더미 영역*/}
        <div className="h-[106px]"></div>

        {/*촬영하기 버튼*/}
        {/*<BottomFloatingButton*/}
        {/*  id="takePhotoButton"*/}
        {/*  dataGtm="pest_diagnosis_poc_pestDiagnosisMain_takePhotoButton_Click"*/}
        {/*  onClick={photoBtnClick}*/}
        {/*>*/}
        {/*  <CameraIconV2 />*/}
        {/*  <span className="ml-1 font-bold text-[19px]">내 작물 진단하기</span>*/}
        {/*</BottomFloatingButton>*/}

        <div className={`fixed bottom-0 ${!capturePanelOpen ? "w-0" : "z-50 w-full max-w-xl"}`}>
          <div className={`relative`}>
            {capturePanelOpen && (
              <div
                className="no-scrollbar fixed inset-0 overflow-y-auto bg-gray-500 opacity-80"
                onClick={photoBtnClick}
              ></div>
            )}
            <div className={`relative h-[145px] ${!capturePanelOpen ? "overflow-y-hidden" : "z-50"}`}>
              <div
                id="capturePanel"
                className={`-bottom-[172px] h-[172px] w-full max-w-xl items-center rounded-t-xl bg-white px-5 py-[30px] ${
                  !capturePanelOpen
                    ? "translate-y-40"
                    : "z-50 -translate-y-[26px] transition-transform delay-100 duration-500"
                }`}
              >
                <div>
                  <label
                    htmlFor="takePhotoMenu"
                    id="takePhotoMenuLabel"
                    className="flex-cols flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/camera2.svg"}
                      width={24}
                      height={24}
                      alt="cameraIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">사진 촬영</div>
                    <input
                      type="file"
                      id="takePhotoMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_takePhotoMenu_Click"
                      capture
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                  <label
                    htmlFor="albumMenu"
                    id="albumMenuLabel"
                    className="flex-cols mt-2 flex h-[52px] w-full cursor-pointer justify-center rounded-xl border border-neutral-300 p-[14px] text-center"
                  >
                    <Image
                      src={"/icons/album.svg"}
                      width={24}
                      height={24}
                      alt="albumIcon"
                      className={`text-zinc-800`}
                    />
                    <div className="ml-1.5 text-base font-bold text-zinc-800">앨범 선택</div>
                    <input
                      type="file"
                      id="albumMenu"
                      data-gtm="pest_diagnosis_poc_requestDiagnosis_selectFileMenu_Click"
                      accept="image/*"
                      className="hidden"
                      onChange={onChange}
                    ></input>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EntrypointV320;
