const MedicineIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 34">
      <g filter="url(#a)" strokeWidth="4" strokeLinejoin="round">
        <path
          d="M8.938 4.958c0-.563.217-1.104.604-1.502A2.032 2.032 0 0 1 11 2.833h11c.27 0 .54.055.79.162.25.107.477.263.668.46.192.198.344.432.448.69a2.18 2.18 0 0 1-.448 2.316 2.06 2.06 0 0 1-.669.46 2.01 2.01 0 0 1-.789.162H11a2.032 2.032 0 0 1-1.458-.622 2.158 2.158 0 0 1-.604-1.503Zm.068 7.169c.193-.248.438-.448.716-.585.278-.137.583-.209.89-.209h11.774c.308 0 .613.071.891.209.278.137.523.337.717.585l3.05 3.912c.295.377.456.847.455 1.331v11.672c0 .563-.217 1.104-.604 1.502a2.032 2.032 0 0 1-1.458.623H7.562a2.032 2.032 0 0 1-1.458-.623 2.158 2.158 0 0 1-.604-1.502V17.37c0-.484.16-.954.454-1.331l3.052-3.912Z"
          fill="#fff"
          stroke="#fff"
        />
        <path d="M16.5 17v8.5m-4.125-4.25h8.25-8.25Z" stroke="#83D88E" strokeLinecap="round" />
      </g>
      <defs>
        <filter
          id="a"
          x="-1.5"
          y="-2.167"
          width="35.999"
          height="42.333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2400_8878" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2400_8878" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default MedicineIcon;
