import { BlurringImage } from "ui";
import { REGEXP } from "@constants/common";

type Props = {
  image: string;
  title: string;
  crop?: string;
  content: string;
  time?: string | Date;
  onClick: () => Promise<void>;
  dataGtm?: string;
};

function EntryList({ image, title, crop, content, time, onClick, dataGtm }: Props) {
  const imgUrlRegEx = new RegExp(REGEXP.ABSOLUTE_URL_PATTERN);

  return (
    <div
      className="flex w-full items-center py-4 border-b border-[#F0F2F5] last:border-0 cursor-pointer"
      onClick={onClick}
      {...(dataGtm && { "data-gtm": dataGtm })}
    >
      <div className="flex relative mr-3 w-[108px] h-[108px] rounded-lg">
        <BlurringImage
          className="rounded-lg object-cover"
          src={imgUrlRegEx.test(image) ? image : ""}
          layout="responsive"
          priority={true}
          width={"100%"}
          height={"100%"}
          sizes="(min-width: 576px) 176px, 30vw"
          alt="diseaseImg"
        />
      </div>
      <div className="basis-2/3 h-[108px]">
        <div className="flex flex-col h-full justify-between">
          <div className="">
            <div className="flex">
              <h4 className="line-clamp-1 leading-normal font-medium text-[19px] break-all">{title}</h4>
              {crop && (
                <span className="ml-1.5 px-1.5 py-[3px] rounded text-[#65666B] text-[13px] bg-[#F7F8FA]">{crop}</span>
              )}
            </div>
            {content && (
              <span className="ellipsis-content text-[15px] whitespace-pre-line leading-normal text-[#65666B] pt-1">
                {content}
              </span>
            )}
          </div>
          <div className="text-[13px] leading-normal text-[#8B8D94] pt-1">
            {time instanceof Date ? `${time.getFullYear()}.${time.getMonth() + 1}.${time.getDate()}` : time}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntryList;
