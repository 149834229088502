const CameraIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
      <g filter="url(#a)" fill="#fff">
        <path d="M21.656 17.063c0 1.409-.543 2.76-1.51 3.756a5.08 5.08 0 0 1-3.646 1.556 5.08 5.08 0 0 1-3.646-1.556 5.395 5.395 0 0 1-1.51-3.756c0-1.41.543-2.76 1.51-3.757A5.08 5.08 0 0 1 16.5 11.75a5.08 5.08 0 0 1 3.646 1.556 5.395 5.395 0 0 1 1.51 3.757Z" />
        <path d="M4.125 7.5a4.064 4.064 0 0 0-2.917 1.245A4.316 4.316 0 0 0 0 11.75V24.5c0 1.127.435 2.208 1.208 3.005a4.065 4.065 0 0 0 2.917 1.245h24.75a4.065 4.065 0 0 0 2.917-1.245A4.316 4.316 0 0 0 33 24.5V11.75a4.316 4.316 0 0 0-1.208-3.005A4.064 4.064 0 0 0 28.875 7.5h-2.417a4.064 4.064 0 0 1-2.917-1.245l-1.707-1.76a4.064 4.064 0 0 0-2.917-1.245h-4.834a4.064 4.064 0 0 0-2.917 1.245L9.46 6.255A4.064 4.064 0 0 1 6.542 7.5H4.125Zm1.031 4.25c-.273 0-.536-.112-.729-.311a1.08 1.08 0 0 1-.302-.752c0-.281.109-.551.302-.75.193-.2.456-.312.73-.312.273 0 .535.112.728.311.194.2.303.47.303.752 0 .281-.11.551-.303.75-.193.2-.455.312-.729.312Zm18.563 5.313c0 1.972-.76 3.864-2.115 5.259A7.113 7.113 0 0 1 16.5 24.5a7.113 7.113 0 0 1-5.104-2.178 7.553 7.553 0 0 1-2.115-5.26c0-1.972.76-3.864 2.115-5.259A7.113 7.113 0 0 1 16.5 9.625c1.915 0 3.75.784 5.104 2.178a7.553 7.553 0 0 1 2.115 5.26Z" />
      </g>
      <defs>
        <filter
          id="a"
          x="-5px"
          y=".25px"
          width="43px"
          height="35.5px"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2400_8871" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2400_8871" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CameraIcon;
