import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { map, pipe, toArray } from "@fxts/core";
import { CROP_IMAGES_NAME } from "@constants/crop_image";
import { checkVersion, QA_FLAG_DEV, QA_FLAG_STAGING } from "@vhows/util";

function CropPopup({ cropPopupClick }: { cropPopupClick: () => void }) {
  const [cropImgArr, setCropImgArr] = useState<string[]>([]);

  useEffect(() => {
    setCropImgArr(
      pipe(
        CROP_IMAGES_NAME,
        map(v => `/images/crop/${v}.png`),
        toArray,
      ),
    );
  }, []);

  const headerClass = checkVersion("3.0.0", QA_FLAG_STAGING)
    ? "fixed z-40 mt-[-56px] h-full w-full overflow-y-auto bg-white"
    : "fixed z-40 -mt-14 h-full w-full overflow-y-auto bg-white";

  return (
    <>
      <div className={`${headerClass}`}>
        {/*헤더 영역*/}
        <div className="fixed z-40 m-auto flex h-14 w-full max-w-xl justify-end bg-white">
          <a
            className="mr-2 h-14 w-14 cursor-pointer p-3 text-center"
            id="cancelButton"
            onClick={(e: any) => {
              cropPopupClick();
            }}
          >
            <XMarkIcon className="" />
          </a>
        </div>

        {/*바디 영역*/}
        <div className="mt-14 py-2.5 px-5">
          {/*상단 텍스트 영역*/}
          <div className="text-2xl font-bold">병해충 진단 작물을 확인해주세요</div>
          <div className="pt-2.5 text-[15px] font-normal">
            보다 정확한 병해충 진단과 농약 처방은 농약사 등 전문가의 처방을 추천드려요.
          </div>

          {/*하단 작물 이미지 영역*/}
          <div className="mt-5 grid max-w-xl grid-cols-5 gap-2.5">
            {cropImgArr.map((crop: string, index: number) => {
              return (
                <div key={index} className="flex flex-col">
                  <div className="relative flex h-14 w-full justify-center overflow-hidden">
                    <img className="object-contain" src={crop} alt={CROP_IMAGES_NAME[index]} />
                  </div>
                  <span className="text-center text-[15px] font-normal text-[#8B8D94]">{CROP_IMAGES_NAME[index]}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default CropPopup;
